import React, { memo } from 'react';

const CardexxIconInner = () => {
    return (
        <svg viewBox="0 0 22 22">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.03265 0H18.3022C19.4199 0 20.3348 0.899722 20.3348 1.99902V17.9993C20.3348 19.1003 19.4199 20 18.3022 20H2.03265C0.914858 20 0 19.1003 0 17.9993V1.99902C0 0.899722 0.914858 0 2.03265 0Z"
                fill="#AF272F"
                fillOpacity="0.968627"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7139 7.86073C16.4135 7.86891 14.9697 7.86727 14.5355 7.86073C13.7255 7.84764 12.9104 7.25873 12.8938 6.46371C12.8821 6.39337 12.8854 6.36065 12.8854 6.29194C12.8904 3.60914 12.8871 2.04362 12.8871 1.59539C12.8871 1.24041 13.2081 1.07356 13.5525 1.05556C13.9084 1.0392 14.291 1.18316 14.291 1.58231C14.291 3.70565 14.291 4.94563 14.291 5.30552C14.291 5.87316 14.6653 6.31157 15.1959 6.39664C15.2591 6.40645 15.3107 6.40809 15.3739 6.40809H18.7638C19.1531 6.413 19.3294 6.75816 19.3294 7.11314C19.3294 7.47958 19.1381 7.85582 18.7139 7.86073Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1714 5.46108C16.1647 5.46108 16.1531 5.45945 16.1381 5.45618C15.4794 5.39892 15.0602 4.91961 15.0802 4.17366V4.11314L15.0819 3.61256L15.0802 3.55531C15.0819 2.86988 15.0819 2.44129 15.0819 2.16974V2.15829V2.13538V2.13375V2.13048C15.0819 2.06831 15.0752 1.99797 15.0835 1.93908C15.0852 1.70352 15.0869 1.67734 15.0902 1.62663C15.1268 0.91994 16.4392 0.807066 16.4625 1.72805C16.4874 2.64904 16.4791 3.13326 16.4791 3.13326C16.4791 3.13326 16.4841 3.21014 16.4841 3.2903C16.4808 3.49478 16.5224 3.85794 16.9382 4.05097C17.1112 4.13113 17.3773 4.11641 17.6867 4.11968C17.8813 4.12132 18.1957 4.11804 18.4901 4.11804C18.6481 4.11804 18.8394 4.10332 18.9775 4.16875C19.1838 4.26691 19.3135 4.4812 19.3335 4.68405C19.3767 5.1061 19.1671 5.46272 18.7696 5.46272L18.4885 5.46435C17.7133 5.46435 16.9631 5.46435 16.1947 5.46272C16.1863 5.46272 16.1797 5.46108 16.1714 5.46108Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4861 10.0056C11.2848 9.88943 11.0835 9.72748 10.9105 9.50664C10.5512 9.07805 10.3433 8.53167 10.345 7.95094L10.3616 1.60872C10.3583 1.22429 10.7958 1.06071 11.1284 1.05743C11.6058 1.06561 11.9052 1.28318 11.9052 1.65289C11.9052 4.51891 11.9052 6.28727 11.9052 6.96125C11.9052 7.89859 12.5922 8.8294 13.522 8.82776C16.8305 8.84085 18.4856 8.79832 18.7933 8.84576C19.0627 8.88011 19.2291 9.09113 19.2923 9.34796C19.3189 9.42321 19.3322 9.51155 19.3289 9.61788C19.3256 9.69967 19.3172 9.77329 19.2989 9.83872C19.2341 10.1414 19.0378 10.3949 18.7218 10.3965L12.8766 10.3933C12.3494 10.3933 11.877 10.2493 11.4861 10.0056Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.56984 12.1824C3.8703 12.1742 5.31411 12.1758 5.74825 12.1824C6.55998 12.1954 7.37337 12.7844 7.39001 13.5794C7.40165 13.6481 7.39832 13.6824 7.39832 13.7511C7.39333 16.434 7.39666 17.9995 7.39666 18.4477C7.39666 18.8027 7.07729 18.9695 6.73131 18.9875C6.37534 19.0039 5.99277 18.8599 5.99277 18.4591C5.99277 16.3374 5.99277 15.0975 5.99277 14.7376C5.99277 14.1683 5.61851 13.7315 5.08789 13.6465C5.02468 13.6366 4.97312 13.635 4.90991 13.635H1.51994C1.13071 13.6301 0.956055 13.2849 0.956055 12.9299C0.956055 12.5635 1.14568 12.1873 1.56984 12.1824Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.11372 14.5821C4.12037 14.5821 4.13202 14.5838 4.14699 14.587C4.80568 14.6427 5.22485 15.1236 5.20489 15.8695V15.9301L5.20323 16.4306L5.20489 16.4879C5.20489 17.1733 5.20323 17.6019 5.20323 17.8735V17.8833V17.9078V17.9127C5.20323 17.9733 5.20988 18.0452 5.20157 18.1041C5.1999 18.3397 5.19824 18.3659 5.19658 18.4166C5.15832 19.1233 3.84591 19.2361 3.82263 18.3152C3.79768 17.3942 3.80599 16.91 3.80599 16.91C3.80599 16.91 3.801 16.8331 3.80267 16.7529C3.80433 16.5484 3.76441 16.1853 3.34856 15.9922C3.17391 15.9121 2.90777 15.9268 2.59838 15.9235C2.40543 15.9219 2.08939 15.9252 1.79497 15.9252C1.63695 15.9252 1.44732 15.9399 1.3076 15.8745C1.10134 15.7763 0.973258 15.562 0.951634 15.3592C0.908387 14.9371 1.11797 14.5805 1.51552 14.5789H1.79663C2.57177 14.5789 3.32361 14.5789 4.09043 14.5805C4.09875 14.5805 4.1054 14.5821 4.11372 14.5821Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.79968 10.0376C9.00095 10.1537 9.20222 10.3157 9.37521 10.5365C9.73616 10.9651 9.94242 11.5115 9.94076 12.0922L9.92579 18.4344C9.92745 18.8189 9.48998 18.9824 9.15731 18.9857C8.67992 18.9775 8.38217 18.76 8.38217 18.3903C8.38217 15.5242 8.38217 13.7559 8.38217 13.0819C8.38217 12.1446 7.69353 11.2138 6.7637 11.2154C3.45524 11.2023 1.80018 11.2448 1.49412 11.1974C1.22299 11.163 1.05831 10.952 0.993443 10.6952C0.968492 10.6199 0.955185 10.53 0.958512 10.4253C0.960175 10.3435 0.970156 10.2699 0.986789 10.2044C1.05166 9.9018 1.24794 9.64824 1.56398 9.64661L7.40909 9.64988C7.93639 9.64988 8.40879 9.79383 8.79968 10.0376Z"
                fill="white"
            />
        </svg>
    );
};

export const CardexxIcon = memo(CardexxIconInner);
