import React, { memo } from 'react';

const InstitutionsInner = () => {
    return (
        <svg viewBox="0 0 20 20">
            <path d="M17.7778 17.7778V10C17.7778 9.38635 17.2803 8.88889 16.6667 8.88889H12.2222C11.6086 8.88889 11.1111 9.38635 11.1111 10V17.7778H13.3333V12.2222C13.3333 11.6086 13.8308 11.1111 14.4444 11.1111C15.0581 11.1111 15.5556 11.6086 15.5556 12.2222V17.7778H17.7778ZM0 13.3333V2C0 1.70531 0 1 0.5 0.5C1 0 1.70531 0 2 0H16C16.2947 0 16.7916 0.291626 17 0.5C17.5 1 17.7778 1.70531 17.7778 2V6.66667C19.0051 6.66667 20 7.66159 20 8.88889V14.4444V17.7778V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V17.7778V13.3333ZM4.44444 8.88889V11.1111H6.66667V8.88889H4.44444ZM4.44444 13.3333V15.5556H6.66667V13.3333H4.44444ZM4.44444 4.44444V6.66667H6.66667V4.44444H4.44444Z" />
        </svg>
    );
};

export const Institutions = memo(InstitutionsInner);
