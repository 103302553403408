import React, { memo } from 'react';

const SettingsInner = () => {
    return (
        <svg viewBox="0 0 20 20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4443 11.58C18.802 11.77 19.078 12.07 19.2721 12.37C19.6502 12.99 19.6196 13.75 19.2517 14.42L18.5363 15.62C18.1582 16.26 17.453 16.66 16.7274 16.66C16.3698 16.66 15.9712 16.56 15.6442 16.36C15.3785 16.19 15.0719 16.13 14.7449 16.13C13.7331 16.13 12.8849 16.96 12.8542 17.95C12.8542 19.1 11.914 20 10.7388 20H9.34892C8.16345 20 7.22325 19.1 7.22325 17.95C7.20281 16.96 6.35458 16.13 5.34284 16.13C5.0056 16.13 4.69901 16.19 4.44352 16.36C4.1165 16.56 3.70771 16.66 3.36025 16.66C2.62444 16.66 1.91929 16.26 1.54116 15.62L0.836012 14.42C0.457888 13.77 0.437449 12.99 0.815573 12.37C0.979086 12.07 1.28567 11.77 1.63314 11.58C1.91929 11.44 2.10324 11.21 2.27697 10.94C2.78795 10.08 2.48136 8.95 1.6127 8.44C0.600962 7.87 0.273935 6.6 0.856451 5.61L1.54116 4.43C2.1339 3.44 3.40112 3.09 4.42308 3.67C5.31219 4.15 6.467 3.83 6.9882 2.98C7.15171 2.7 7.24368 2.4 7.22325 2.1C7.20281 1.71 7.31522 1.34 7.50939 1.04C7.88752 0.42 8.57223 0.02 9.31826 0H10.7592C11.5155 0 12.2002 0.42 12.5783 1.04C12.7623 1.34 12.8849 1.71 12.8542 2.1C12.8338 2.4 12.9258 2.7 13.0893 2.98C13.6105 3.83 14.7653 4.15 15.6646 3.67C16.6764 3.09 17.9538 3.44 18.5363 4.43L19.221 5.61C19.8138 6.6 19.4867 7.87 18.4648 8.44C17.5961 8.95 17.2895 10.08 17.8107 10.94C17.9742 11.21 18.1582 11.44 18.4443 11.58ZM7.15171 10.01C7.15171 11.58 8.44959 12.83 10.0541 12.83C11.6585 12.83 12.9258 11.58 12.9258 10.01C12.9258 8.44 11.6585 7.18 10.0541 7.18C8.44959 7.18 7.15171 8.44 7.15171 10.01Z"
            />
        </svg>
    );
};

export const Settings = memo(SettingsInner);
