import React, { memo } from 'react';

const ReportsInner = () => {
    return (
        <svg viewBox="0 0 19 20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.23529 0H13.6163C16.7053 0 18.4253 1.78 18.4253 4.83V15.16C18.4253 18.26 16.7053 20 13.6163 20H5.23529C2.19529 20 0.425293 18.26 0.425293 15.16V4.83C0.425293 1.78 2.19529 0 5.23529 0ZM5.50529 4.66V4.65H8.49429C8.92529 4.65 9.27529 5 9.27529 5.429C9.27529 5.87 8.92529 6.22 8.49429 6.22H5.50529C5.07429 6.22 4.72529 5.87 4.72529 5.44C4.72529 5.01 5.07429 4.66 5.50529 4.66ZM5.50529 10.74H13.3453C13.7753 10.74 14.1253 10.39 14.1253 9.96C14.1253 9.53 13.7753 9.179 13.3453 9.179H5.50529C5.07429 9.179 4.72529 9.53 4.72529 9.96C4.72529 10.39 5.07429 10.74 5.50529 10.74ZM5.50529 15.31H13.3453C13.7443 15.27 14.0453 14.929 14.0453 14.53C14.0453 14.12 13.7443 13.78 13.3453 13.74H5.50529C5.20529 13.71 4.91529 13.85 4.75529 14.11C4.59529 14.36 4.59529 14.69 4.75529 14.95C4.91529 15.2 5.20529 15.35 5.50529 15.31Z"
            />
        </svg>
    );
};

export const Reports = memo(ReportsInner);
