import React, { memo } from 'react';

export const JqueryLogoInner = () => {
    return (
        <svg viewBox="0 0 255.99 57.88">
            <g>
                <path
                    d="M45,36.46a18.15,18.15,0,0,1-8.48,5.72c-7.09,2.35-14.79.9-21.11-3.12C9.25,34.84,4.88,28.34,4.23,20.9a18.06,18.06,0,0,1,1.91-10C2,16.07,0,18.13,0,25.56,0,33.17,5.18,40.73,11.54,45s15.39,5.89,22.4,2.92C40.78,45,41.89,42.36,45,36.46Z"
                    transform="translate(0)"
                />
                <path
                    d="M46.22,25.4a13.18,13.18,0,0,1-6.13,4.14,18,18,0,0,1-15.26-2.26,18,18,0,0,1-8.11-13.13A13.11,13.11,0,0,1,18.1,6.89c-3,3.77-4.46,5.26-4.44,10.63s3.75,11,8.34,14.05,11.14,4.26,16.2,2.11S44,29.67,46.22,25.4Z"
                    transform="translate(0)"
                />
                <path
                    d="M45,16.19a8.14,8.14,0,0,1-3.83,2.58,11.21,11.21,0,0,1-9.53-1.41,11.24,11.24,0,0,1-5.06-8.2,8.24,8.24,0,0,1,.86-4.54C25.55,7,24.65,7.91,24.67,11.27S27,18.11,29.88,20s7,2.66,10.12,1.32S43.59,18.85,45,16.19Z"
                    transform="translate(0)"
                />
            </g>
            <path
                d="M75.31,17.66H67.05a.6.6,0,0,1-.58-.73L68,10.06a1,1,0,0,1,1-.78h8.2a.59.59,0,0,1,.58.72L76.3,16.86A1,1,0,0,1,75.31,17.66Z"
                transform="translate(0)"
            />
            <path
                d="M66.68,20h8.24a.58.58,0,0,1,.57.71L69.68,47.18c-1.08,5.2-4,10.7-11,10.7H51.52a.59.59,0,0,1-.57-.72l1.7-7.27a1.06,1.06,0,0,1,1.07-.82l2.35.06c1.9,0,3.63-1.71,4.29-4.47l5.27-23.81A1.08,1.08,0,0,1,66.68,20Z"
                transform="translate(0)"
            />
            <path
                d="M120.64,18.82C121.91,11.13,118.06,0,103.22,0S82.05,10.63,79.61,22.18,80.37,43.94,95,43.86h22.4a1.05,1.05,0,0,0,1-.74l1.88-7.2a.54.54,0,0,0-.56-.74h-3.63c-.35,0-.6-.3-.5-.55S119.71,24.55,120.64,18.82Zm-11,3-3.55,12.6a1.06,1.06,0,0,1-1,.73H96.48c-6.57,0-8.1-5.4-6.5-13,1.6-7.76,4.67-12.43,11.14-12.91,8.85-.67,10.69,5.39,8.55,12.58Z"
                transform="translate(0)"
            />
            <path
                d="M157.62,9.28h-7.51a1,1,0,0,0-.92.74L143.9,34.44a1,1,0,0,1-.92.74h-5.31c-5.26,0-4.69-3.4-3.61-8.38L137.43,10a.63.63,0,0,0-.61-.74h-7.71a1,1,0,0,0-.91.74l-3.47,16.46c-1.9,8.72-1.42,17.11,9,17.37h16.58a1,1,0,0,0,.92-.74l7-33.09A.6.6,0,0,0,157.62,9.28Z"
                transform="translate(0)"
            />
            <path
                d="M178.65,9.28h0c-14.61,0-18,8.65-20,17.37-1.94,8.88-1.78,17.21,13.32,17.21h13.82a1,1,0,0,0,.91-.73l1.51-7.21a.59.59,0,0,0-.6-.74H174c-4.85,0-6.67-1-6.55-4.15a1.23,1.23,0,0,1,1.24-1.17H189.3a1,1,0,0,0,.85-.74C193.37,17.26,192.45,9.28,178.65,9.28ZM182,22.09a.91.91,0,0,1-.89.76H169.72a.48.48,0,0,1-.54-.59s.06-.24.06-.24c1.09-2.82,3.3-4.67,7.46-4.67,4.68,0,5.59,2.23,5.27,4.74Z"
                transform="translate(0)"
            />
            <path
                d="M199.35,9.73,192.24,43.2a.55.55,0,0,0,.53.66h7.87a1,1,0,0,0,.94-.77l5-24.52a.86.86,0,0,1,.84-.69l19.81-.06a.57.57,0,0,0,.56-.71l-.9-3.7c-.59-2.43-2.58-4.13-6.58-4.13H199.91A.56.56,0,0,0,199.35,9.73Z"
                transform="translate(0)"
            />
            <path
                d="M218.65,20h9a1,1,0,0,1,.93.74l1.06,4.63a.25.25,0,0,0,.46.09L244.18,4.91a1.54,1.54,0,0,1,1.26-.66h10.21a.34.34,0,0,1,.27.54L233.25,34.65a3.27,3.27,0,0,0-.57,1.17l-1.86,7.32a1,1,0,0,1-.93.72H221a.56.56,0,0,1-.54-.71l1.94-7.36a2.26,2.26,0,0,0,0-1.21l-4.19-13.91A.54.54,0,0,1,218.65,20Z"
                transform="translate(0)"
            />
        </svg>
    );
};

export const JqueryLogo = memo(JqueryLogoInner);
