import React, { memo } from 'react';

const ImportInner = () => {
    return (
        <svg viewBox="0 0 17 20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.65347 14.64L11.495 11.77C11.7822 11.47 11.7822 11 11.4851 10.71C11.198 10.42 10.7327 10.42 10.4455 10.71L8.87129 12.31V7.49C8.87129 7.07 8.54455 6.74 8.13861 6.74C7.73267 6.74 7.39604 7.07 7.39604 7.49V12.31L5.82178 10.71C5.53465 10.42 5.06931 10.42 4.78218 10.71C4.49505 11 4.49505 11.47 4.78218 11.77L7.61386 14.64C7.68317 14.71 7.76238 14.76 7.85148 14.8C7.94059 14.84 8.0396 14.86 8.13861 14.86C8.23762 14.86 8.32673 14.84 8.41584 14.8C8.50495 14.76 8.58416 14.71 8.65347 14.64ZM15.8282 7.02561C16.0609 7.02292 16.3143 7.02 16.5446 7.02C16.802 7.02 17 7.22 17 7.47V15.51C17 17.99 15 20 12.5446 20H4.67327C2.08911 20 0 17.89 0 15.29V4.51C0 2.03 1.9901 0 4.46535 0H9.75247C10 0 10.2079 0.21 10.2079 0.46V3.68C10.2079 5.51 11.6931 7.01 13.5149 7.02C13.9333 7.02 14.3077 7.02318 14.6346 7.02595C14.8878 7.02809 15.1125 7.03 15.3069 7.03C15.4479 7.03 15.6306 7.02789 15.8282 7.02561ZM16.1045 5.5661C15.2916 5.5691 14.3322 5.5661 13.6421 5.5591C12.547 5.5591 11.645 4.6481 11.645 3.5421V0.9061C11.645 0.4751 12.1629 0.2611 12.4579 0.5721C13.2203 1.37199 14.3873 2.5978 15.3738 3.63395C15.7735 4.05379 16.1436 4.44249 16.445 4.7591C16.7342 5.0621 16.5223 5.5651 16.1045 5.5661Z"
            />
        </svg>
    );
};

export const Import = memo(ImportInner);
