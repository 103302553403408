import React, { memo } from 'react';

const ImportExportInner = () => {
    return (
        <svg viewBox="0 0 20 19">
            <path d="M9.2301 4.79052V0.781505C9.2301 0.355229 9.5701 0 10.0001 0C10.3851 0 10.7113 0.298491 10.763 0.67658L10.7701 0.781505V4.79052L15.55 4.79083C17.93 4.79083 19.8853 6.73978 19.9951 9.17041L20 9.38609V14.4254C20 16.873 18.1127 18.8822 15.768 18.995L15.56 19H4.44C2.06 19 0.11409 17.0608 0.00483778 14.6213L0 14.4047L0 9.37576C0 6.9281 1.87791 4.90921 4.22199 4.79585L4.43 4.79083H9.23V11.1932L7.63 9.54099C7.33 9.23119 6.84 9.23119 6.54 9.54099C6.39 9.69588 6.32 9.90241 6.32 10.1089C6.32 10.2659 6.3648 10.4295 6.45952 10.5679L6.54 10.6666L9.45 13.6819C9.59 13.8368 9.79 13.9194 10 13.9194C10.1667 13.9194 10.3333 13.862 10.4653 13.7533L10.54 13.6819L13.45 10.6666C13.75 10.3568 13.75 9.85078 13.45 9.54099C13.1773 9.25936 12.7475 9.23375 12.4462 9.46418L12.36 9.54099L10.77 11.1932V4.79083L9.2301 4.79052Z" />
        </svg>
    );
};

export const ImportExport = memo(ImportExportInner);
