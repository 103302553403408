import React, { memo } from 'react';

const WorkingTimeAccountingInner = () => {
    return (
        <svg width="631" height="414" viewBox="0 0 631 414" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M524.5 131.5C553 61.0001 425.5 -54.9998 328.5 34.0002C291.5 69.5003 278.5 64.5 269.5 55.5001C260.5 46.5002 232.563 13.6701 192.778 26.1792C124.948 47.5054 118.412 117.768 124.973 140.663C58.7053 140.663 29.4999 205.5 82.4999 248.5C-67.5001 267 -3.00014 419.5 202 364.5C328.5 474 493 364.5 493 364.5C493 364.5 636.5 417 612 286C648.869 234.437 634 140.663 524.5 131.5Z"
                fill="url(#paint0_linear)"
            />
            <path d="M230 126.39V281.18L388.42 189.71V49L270.62 117.01L266.56 105.29L230 126.39Z" fill="#1D68D9" />
            <path
                opacity="0.5"
                d="M230 126.39V281.18L388.42 189.71V49L270.62 117.01L266.56 105.29L230 126.39Z"
                fill="black"
            />
            <path d="M230 281.18L238.12 140.46L396.54 49L388.42 189.71L230 281.18Z" fill="#E0E0E0" />
            <path d="M230 281.18L242.18 142.81L400.6 51.3501L388.42 189.71L230 281.18Z" fill="#1D68D9" />
            <path opacity="0.5" d="M230 281.18L242.18 142.81L400.6 51.3501L388.42 189.71L230 281.18Z" fill="black" />
            <path
                d="M361.61 343.86C333.18 327.44 306.8 309.6 294.75 288.65L331.31 267.54L361.61 343.86Z"
                fill="#E0E0E0"
            />
            <path d="M256 156.24V311.03L414.42 219.56V78.8501L296.62 146.86L292.56 135.14L256 156.24Z" fill="#1651AA" />
            <path
                opacity="0.2"
                d="M256 156.24V311.03L414.42 219.56V78.8501L296.62 146.86L292.56 135.14L256 156.24Z"
                fill="#1D68D9"
            />
            <path
                d="M301.64 202.01C301.24 202.01 301.18 201.55 301.18 201.27V69.25C301.18 68.32 301.86 67.15 302.66 66.68L417 0.67C417.19 0.56 417.37 0.5 417.52 0.5C417.92 0.5 417.98 0.96 417.98 1.24V133.26C417.98 134.19 417.3 135.36 416.5 135.83L302.16 201.84C301.97 201.95 301.79 202.01 301.64 202.01Z"
                fill="white"
            />
            <path
                d="M417.46 1.01C417.47 1.06 417.49 1.13 417.49 1.24V133.26C417.49 134 416.9 135.02 416.26 135.39L301.91 201.4C301.82 201.45 301.75 201.48 301.7 201.49C301.69 201.44 301.68 201.37 301.68 201.26V69.25C301.68 68.51 302.27 67.49 302.91 67.12L417.25 1.1C417.34 1.05 417.41 1.02 417.46 1.01ZM417.52 0C417.29 0 417.02 0.08 416.75 0.24L302.41 66.25C301.45 66.8 300.68 68.15 300.68 69.25V201.27C300.68 202.05 301.07 202.51 301.64 202.51C301.87 202.51 302.14 202.43 302.41 202.27L416.75 136.26C417.71 135.71 418.48 134.36 418.48 133.26V1.24C418.48 0.45 418.09 0 417.52 0Z"
                fill="#1D68D9"
            />
            <path
                opacity="0.7"
                d="M417.46 1.01C417.47 1.06 417.49 1.13 417.49 1.24V133.26C417.49 134 416.9 135.02 416.26 135.39L301.91 201.4C301.82 201.45 301.75 201.48 301.7 201.49C301.69 201.44 301.68 201.37 301.68 201.26V69.25C301.68 68.51 302.27 67.49 302.91 67.12L417.25 1.1C417.34 1.05 417.41 1.02 417.46 1.01ZM417.52 0C417.29 0 417.02 0.08 416.75 0.24L302.41 66.25C301.45 66.8 300.68 68.15 300.68 69.25V201.27C300.68 202.05 301.07 202.51 301.64 202.51C301.87 202.51 302.14 202.43 302.41 202.27L416.75 136.26C417.71 135.71 418.48 134.36 418.48 133.26V1.24C418.48 0.45 418.09 0 417.52 0Z"
                fill="white"
            />
            <path opacity="0.5" d="M312.87 74.2801L353.49 50.8301V97.7401L312.87 121.19V74.2801Z" fill="#1D68D9" />
            <path
                d="M347.4 62.5601V76.6301L346.13 77.3401C346.13 77.3401 346.13 75.4101 346.13 73.0301C346.13 70.6501 344.19 69.8401 341.8 71.2201L324.57 81.1701C322.18 82.5501 320.24 85.6001 320.24 87.9801V92.2901L318.97 93.0501V78.9701L347.4 62.5601Z"
                fill="white"
            />
            <path
                d="M339.56 98.4203C337.48 99.6203 335.79 98.3603 335.79 95.6003V73.0303L330.57 75.8703V98.6103C330.57 101.37 328.88 104.59 326.8 105.79L323.03 107.97V109.46L343.34 97.7303V96.2403C343.33 96.2403 341.64 97.2203 339.56 98.4203Z"
                fill="white"
            />
            <path
                opacity="0.7"
                d="M361.61 48.4898C361.61 49.7898 362.77 50.1598 364.21 49.3398L403.7 26.5398C405.13 25.7098 406.3 23.9898 406.3 22.6898C406.3 21.3998 405.14 21.0198 403.7 21.8398L364.21 44.6398C362.78 45.4698 361.61 47.1898 361.61 48.4898Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M361.61 62.5597C361.61 63.8596 362.77 64.2296 364.21 63.4096L403.7 40.6097C405.13 39.7797 406.3 38.0596 406.3 36.7596C406.3 35.4696 405.14 35.0896 403.7 35.9096L364.21 58.7096C362.78 59.5396 361.61 61.2597 361.61 62.5597Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M361.61 76.63C361.61 77.93 362.77 78.3 364.21 77.48L403.7 54.68C405.13 53.85 406.3 52.13 406.3 50.83C406.3 49.54 405.14 49.16 403.7 49.98L364.21 72.78C362.78 73.61 361.61 75.33 361.61 76.63Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M361.61 90.6998C361.61 91.9998 362.77 92.3698 364.21 91.5498L403.7 68.7498C405.13 67.9198 406.3 66.1998 406.3 64.8998C406.3 63.6098 405.14 63.2298 403.7 64.0498L364.21 86.8498C362.78 87.6798 361.61 89.3998 361.61 90.6998Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M312.87 132.92C312.87 134.22 314.03 134.59 315.47 133.77L403.7 82.8299C405.13 81.9999 406.3 80.2799 406.3 78.9799C406.3 77.6899 405.14 77.3099 403.7 78.1299L315.47 129.07C314.03 129.9 312.87 131.62 312.87 132.92Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M312.87 146.99C312.87 148.28 314.03 148.66 315.47 147.84L403.7 96.8997C405.13 96.0697 406.3 94.3497 406.3 93.0497C406.3 91.7497 405.14 91.3797 403.7 92.1997L315.47 143.14C314.03 143.97 312.87 145.69 312.87 146.99Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M312.87 161.06C312.87 162.35 314.03 162.73 315.47 161.91L403.7 110.97C405.13 110.14 406.3 108.42 406.3 107.12C406.3 105.82 405.14 105.45 403.7 106.27L315.47 157.21C314.03 158.04 312.87 159.77 312.87 161.06Z"
                fill="#293440"
            />
            <path d="M256 311.03L284.43 186.73L442.85 95.27L414.42 219.56L256 311.03Z" fill="#1D68D9" />
            <path d="M294.759 196.47V288.65L331.309 267.54L331.319 175.36L294.759 196.47Z" fill="#1D68D9" />
            <path
                opacity="0.2"
                d="M294.759 196.47V288.65L331.309 267.54L331.319 175.36L294.759 196.47Z"
                fill="#1D68D9"
            />
            <path
                d="M361.649 343.26C358.539 340.95 328.569 318.03 313.329 285.3C297.109 250.46 297.119 209.82 297.129 194.53V194.05L405.809 131.31C405.799 146.75 405.829 187.44 422.109 222.4C436.999 254.37 465.829 277.02 470.399 280.48L361.649 343.26Z"
                fill="white"
            />
            <path
                d="M405.3 132.18C405.3 148.26 405.61 188.19 421.64 222.62C436.03 253.51 463.39 275.74 469.48 280.43L361.68 342.67C357.47 339.49 328.61 316.97 313.77 285.09C297.59 250.35 297.61 209.8 297.61 194.53V194.34L405.3 132.18ZM406.3 130.45L296.62 193.77C296.62 208.16 296.32 249.97 312.87 285.52C329.12 320.41 361.61 343.87 361.61 343.87L471.29 280.55C471.29 280.55 438.79 257.1 422.54 222.2C405.99 186.64 406.3 144.83 406.3 130.45Z"
                fill="#1D68D9"
            />
            <path
                opacity="0.7"
                d="M405.3 132.18C405.3 148.26 405.61 188.19 421.64 222.62C436.03 253.51 463.39 275.74 469.48 280.43L361.68 342.67C357.47 339.49 328.61 316.97 313.77 285.09C297.59 250.35 297.61 209.8 297.61 194.53V194.34L405.3 132.18ZM406.3 130.45L296.62 193.77C296.62 208.16 296.32 249.97 312.87 285.52C329.12 320.41 361.61 343.87 361.61 343.87L471.29 280.55C471.29 280.55 438.79 257.1 422.54 222.2C405.99 186.64 406.3 144.83 406.3 130.45Z"
                fill="white"
            />
            <path
                opacity="0.7"
                d="M393.89 151.68L359.2 171.71C357.76 172.54 356.63 174.24 356.68 175.5C356.73 176.77 357.94 177.12 359.38 176.29L394.07 156.26C395.5 155.43 396.62 153.73 396.57 152.47C396.52 151.2 395.33 150.85 393.89 151.68Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M394.63 165.32L359.94 185.35C358.5 186.18 357.42 187.85 357.52 189.09C357.63 190.32 358.89 190.65 360.32 189.82L395.01 169.79C396.44 168.96 397.51 167.29 397.4 166.06C397.31 164.82 396.07 164.49 394.63 165.32Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M396.02 178.59L361.33 198.62C359.89 199.45 358.86 201.09 359.03 202.29C359.2 203.49 360.51 203.79 361.95 202.96L396.64 182.93C398.07 182.1 399.09 180.46 398.92 179.26C398.74 178.06 397.45 177.77 396.02 178.59Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M398.1 191.46L363.41 211.49C361.97 212.32 360.99 213.93 361.23 215.09C361.47 216.25 362.83 216.51 364.27 215.69L398.96 195.66C400.39 194.83 401.36 193.22 401.12 192.06C400.88 190.91 399.53 190.64 398.1 191.46Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M400.92 203.911L366.23 223.941C364.8 224.771 363.87 226.351 364.18 227.471C364.49 228.591 365.91 228.821 367.35 227.991L402.04 207.961C403.48 207.131 404.38 205.561 404.07 204.441C403.75 203.321 402.35 203.081 400.92 203.911Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M404.53 215.9L319.99 264.7C318.56 265.53 317.69 267.07 318.08 268.15C318.47 269.22 319.96 269.41 321.39 268.59L405.93 219.78C407.36 218.95 408.2 217.41 407.81 216.34C407.42 215.27 405.96 215.07 404.53 215.9Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M409.03 227.34L324.49 276.15C323.06 276.98 322.27 278.48 322.74 279.5C323.21 280.52 324.77 280.67 326.2 279.85L410.74 231.04C412.18 230.21 412.95 228.72 412.47 227.69C412.01 226.68 410.47 226.52 409.03 227.34Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M414.55 238.261L330.01 287.071C328.58 287.901 327.86 289.361 328.43 290.331C329 291.301 330.64 291.401 332.07 290.571L416.61 241.761C418.05 240.931 418.73 239.481 418.16 238.521C417.59 237.551 415.98 237.431 414.55 238.261Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M421.05 248.57L336.51 297.38C335.08 298.21 334.43 299.63 335.09 300.55C335.75 301.47 337.45 301.53 338.88 300.7L423.42 251.89C424.85 251.06 425.48 249.65 424.82 248.74C424.17 247.82 422.49 247.74 421.05 248.57Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M428.429 258.38L343.889 307.19C342.459 308.02 341.869 309.41 342.599 310.28C343.329 311.15 345.089 311.18 346.529 310.35L431.069 261.54C432.499 260.71 433.069 259.34 432.339 258.47C431.609 257.6 429.869 257.55 428.429 258.38Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M436.6 267.731L352.06 316.541C350.62 317.371 350.1 318.721 350.9 319.561C351.7 320.391 353.52 320.391 354.95 319.561L439.49 270.751C440.92 269.921 441.43 268.581 440.63 267.751C439.84 266.911 438.04 266.901 436.6 267.731Z"
                fill="#293440"
            />
            <path
                opacity="0.7"
                d="M445.54 276.65L361 325.46C359.57 326.29 359.1 327.61 359.97 328.41C360.84 329.2 362.71 329.17 364.15 328.34L448.69 279.53C450.12 278.7 450.58 277.4 449.71 276.6C448.83 275.8 446.97 275.82 445.54 276.65Z"
                fill="#293440"
            />
            <path
                opacity="0.5"
                d="M346.639 178.96L306.759 201.99C307.199 216.43 308.849 237.28 314.899 258.27L354.779 235.24C348.729 214.25 347.079 193.4 346.639 178.96Z"
                fill="#1D68D9"
            />
            <path
                opacity="0.4"
                d="M323.02 198.79C323.02 196.55 322.29 194.83 321.07 193.72L306.75 201.99C306.87 205.81 307.07 210.08 307.42 214.67C308.99 214.93 310.84 214.64 312.84 213.72C318.46 211.09 323.02 204.41 323.02 198.79Z"
                fill="#1D68D9"
            />
            <path
                opacity="0.4"
                d="M351.389 221.44C348.729 218.08 343.319 207.74 341.009 203.23C340.599 202.43 339.429 202.54 339.159 203.4C335.169 216.22 334.829 229.65 334.829 229.65C331.619 227.11 329.319 224.59 326.359 220.23C325.929 219.6 324.959 219.77 324.769 220.51C320.409 237.3 322.599 253.82 322.599 253.82L354.769 235.25C353.439 230.61 352.329 225.99 351.389 221.44Z"
                fill="#1D68D9"
            />
            <defs>
                <linearGradient id="paint0_linear" x1="332" y1="14" x2="332" y2="355" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D9E9FE" />
                    <stop offset="1" stopColor="#D9E9FE" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const WorkingTimeAccounting = memo(WorkingTimeAccountingInner);
