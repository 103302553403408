import React, { memo } from 'react';

const SearchInner = () => {
    return (
        <svg viewBox="0 0 14 14">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.50575 6.59439C1.50575 3.7663 3.84824 1.47368 6.73786 1.47368C9.62747 1.47368 11.97 3.7663 11.97 6.59439C11.97 9.42248 9.62747 11.7151 6.73786 11.7151C3.84824 11.7151 1.50575 9.42248 1.50575 6.59439ZM6.73786 0C3.01664 0 0 2.95241 0 6.59439C0 10.2364 3.01664 13.1888 6.73786 13.1888C8.24795 13.1888 9.64201 12.7026 10.7655 11.8814L12.7155 13.7849C13.0099 14.0722 13.4865 14.0716 13.7802 13.7835C14.0738 13.4954 14.0732 13.0288 13.7788 12.7415L11.8649 10.8732C12.8693 9.7215 13.4757 8.22725 13.4757 6.59439C13.4757 2.95241 10.4591 0 6.73786 0Z"
            />
        </svg>
    );
};

export const Search = memo(SearchInner);
