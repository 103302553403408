import React, { memo } from 'react';

const BlockLockInner = () => {
    return (
        <svg viewBox="0 0 17 20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0227 5.39601V6.92935C15.7451 7.46696 17 9.02614 17 10.8884V15.8253C17 18.1308 15.0886 20 12.7322 20H4.2688C1.91136 20 0 18.1308 0 15.8253V10.8884C0 9.02614 1.25595 7.46696 2.97729 6.92935V5.39601C2.98745 2.41479 5.45667 0 8.48476 0C11.5535 0 14.0227 2.41479 14.0227 5.39601ZM8.50508 1.73904C10.5678 1.73904 12.2445 3.37871 12.2445 5.39601V6.7137H4.75553V5.37613C4.76569 3.36878 6.44232 1.73904 8.50508 1.73904ZM10 11.5C10 12.1531 9.5826 12.7087 9 12.9146V15.5C9 15.7761 8.77614 16 8.5 16C8.22386 16 8 15.7761 8 15.5V12.9146C7.4174 12.7087 7 12.1531 7 11.5C7 10.6716 7.67157 10 8.5 10C9.32843 10 10 10.6716 10 11.5Z"
            />
        </svg>
    );
};

export const BlockLock = memo(BlockLockInner);
