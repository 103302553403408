import React, { memo } from 'react';

const ControlInner = () => {
    return (
        <svg viewBox="0 0 18 21">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.6395 20.9093C8.75578 20.9701 8.8858 21.001 9.01582 21C9.14584 20.9989 9.2748 20.967 9.39214 20.9052L13.1384 18.9026C14.2008 18.3363 15.0327 17.7031 15.6817 16.9658C17.0929 15.3596 17.8635 13.3096 17.8498 11.1958L17.8054 4.22308C17.8012 3.41997 17.2737 2.70334 16.4936 2.44284L9.52427 0.104542C9.10461 -0.0375472 8.64478 -0.0344583 8.23253 0.11175L1.28966 2.53345C0.513764 2.80424 -0.0042026 3.52602 2.56955e-05 4.33016L0.0444228 11.2977C0.0581648 13.4146 0.8552 15.4554 2.28965 17.0451C2.94504 17.772 3.78435 18.396 4.85834 18.953L8.6395 20.9093ZM7.64769 12.7141C7.80414 12.8645 8.0071 12.9386 8.21005 12.9365C8.41301 12.9355 8.61491 12.8593 8.76925 12.7069L12.8633 8.67075C13.1709 8.36701 13.1677 7.87896 12.857 7.57934C12.5451 7.27971 12.043 7.28177 11.7354 7.58551L8.19843 11.0719L6.75023 9.67979C6.4384 9.38017 5.93734 9.38326 5.62868 9.687C5.32107 9.99074 5.32424 10.4788 5.63608 10.7784L7.64769 12.7141Z"
            />
        </svg>
    );
};

export const Control = memo(ControlInner);
