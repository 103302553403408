import React, { memo } from 'react';

const BlockUnLockInner = () => {
    return (
        <svg viewBox="0 0 18 21">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7 7.59999H4.7V7.49999L4.6 6.79999C4.4 4.9 5.9 2.99999 7.9 2.79999C9.5 2.59999 11 3.4 11.7 4.79999C11.9 5.2 12.5 5.39999 12.9 5.19999C13.1 5.09999 13.3 4.9 13.4 4.7C13.5 4.49999 13.5 4.2 13.4 3.99999C12.4 1.9 10.1 0.699995 7.7 0.999995C4.7 1.39999 2.5 4.09999 2.9 6.99999L3 7.79999C1.2 8.39999 0 9.9 0 11.8V16.7C0 19 1.9 20.9 4.3 20.9H12.8C15.2 20.9 17.1 19 17.1 16.7V11.8C17 9.5 15.1 7.59999 12.7 7.59999ZM10 12.5C10 13.1531 9.5826 13.7087 9 13.9146V16.5C9 16.7761 8.77614 17 8.5 17C8.22386 17 8 16.7761 8 16.5V13.9146C7.4174 13.7087 7 13.1531 7 12.5C7 11.6716 7.67157 11 8.5 11C9.32843 11 10 11.6716 10 12.5Z"
            />
        </svg>
    );
};

export const BlockUnLock = memo(BlockUnLockInner);
