import React, { memo } from 'react';

const RefreshInner = () => {
    return (
        <svg viewBox="0 0 17 17">
            <path d="M14.0621 2.49687C12.5672 0.95625 10.5157 0 8.23727 0C3.68048 0 0 3.80375 0 8.5C0 13.1962 3.68048 17 8.23727 17C11.5944 17 14.4644 14.935 15.7496 11.9715C16.036 11.3113 15.523 10.625 14.8217 10.625C14.3666 10.625 13.9691 10.9259 13.7661 11.3459C12.7548 13.4379 10.6588 14.875 8.23727 14.875C4.82483 14.875 2.05159 12.0169 2.05159 8.5C2.05159 4.98313 4.82483 2.125 8.23727 2.125C9.94864 2.125 11.4744 2.85812 12.5879 4.01625L10.303 6.375C9.98991 6.69771 10.1912 7.25049 10.6329 7.28084L17 7.96875L16.5603 1.16767C16.5309 0.712335 15.9952 0.504657 15.6822 0.827244L14.0621 2.49687Z" />
        </svg>
    );
};

export const Refresh = memo(RefreshInner);
