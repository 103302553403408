import React from 'react';
import './PleaseSelectRow.scss';

const PleaseSelectRow = () => (
    <div className="please-select-row">
        <span>Выберите строку для отображения сведений</span>
    </div>
);

export default PleaseSelectRow;
