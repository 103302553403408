import React, { memo } from 'react';

const PrintInner = () => {
    return (
        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 1.58333H6.33333C5.45888 1.58333 4.75 2.29222 4.75 3.16667V7.38889H16.3611V4.5H15C13.8954 4.5 13 3.60457 13 2.5V1.58333ZM0 10.5556C0 8.80665 0 6.38889 3.16667 6.38889L3.16667 3.16667C3.16667 1.41777 4.58443 0 6.33333 0H14C16 0 17.9444 2 17.9444 3.16667V6.38889C21.1111 6.38889 21.1111 8.80665 21.1111 10.5556V13.7222C21.1111 14.8882 20.1659 15.8333 19 15.8333H17.9444C17.9444 17.5822 16.5267 19 14.7778 19H6.33333C4.58443 19 3.16667 17.5822 3.16667 15.8333H2.11111C0.945177 15.8333 0 14.8882 0 13.7222V10.5556ZM14.7778 17.4167C15.6522 17.4167 16.3611 16.7078 16.3611 15.8333H16.3579C16.36 15.7984 16.3611 15.7632 16.3611 15.7278V14.25H4.75V15.7278C4.75 15.7632 4.75109 15.7984 4.75324 15.8333H4.75C4.75 16.7078 5.45888 17.4167 6.33333 17.4167H6.43889H14.6722H14.7778ZM19 11.6111C19.583 11.6111 20.0556 11.1385 20.0556 10.5556C20.0556 9.97259 19.583 9.5 19 9.5C18.417 9.5 17.9444 9.97259 17.9444 10.5556C17.9444 11.1385 18.417 11.6111 19 11.6111Z"
                fill="#293440"
            />
        </svg>
    );
};

export const Print = memo(PrintInner);
