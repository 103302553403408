import React, { memo } from 'react';

export const EslintLogoInner = () => {
    return (
        <svg viewBox="0 0 90 22">
            <path
                d="M7.4,8l4.8-2.8c0.1-0.1,0.3-0.1,0.4,0L17.3,8c0.1,0.1,0.2,0.2,0.2,0.3v5.5c0,0.1-0.1,0.3-0.2,0.3l-4.8,2.8
               c-0.1,0.1-0.3,0.1-0.4,0l-4.8-2.8c-0.1-0.1-0.2-0.2-0.2-0.3V8.3C7.2,8.2,7.3,8.1,7.4,8z"
            />
            <path
                d="M24.6,10.4L19,0.6C18.8,0.3,18.4,0,18,0H6.8C6.3,0,6,0.3,5.8,0.6l-5.6,9.8c-0.2,0.4-0.2,0.8,0,1.2l5.6,9.7
               c0.2,0.4,0.6,0.5,1,0.5H18c0.4,0,0.8-0.2,1-0.5l5.6-9.8C24.8,11.2,24.8,10.8,24.6,10.4z M19.9,15.2c0,0.1-0.1,0.3-0.2,0.4
               l-7.1,4.1c-0.1,0.1-0.3,0.1-0.4,0L5,15.5c-0.1-0.1-0.2-0.2-0.2-0.4V6.9c0-0.1,0.1-0.3,0.2-0.4l7.1-4.1c0.1-0.1,0.3-0.1,0.4,0
               l7.2,4.1c0.1,0.1,0.2,0.2,0.2,0.4V15.2z"
            />
            <path
                className="st0"
                d="M31.1,21.6V5.2h9.4V7h-7.3v5.1h6.2v1.8h-6.2v5.9h7.6v1.8H31.1z M47.7,21.9c-1.1,0-2.2-0.2-3.2-0.6
               c-1-0.4-1.8-1-2.5-1.8l1.2-1.4c0.6,0.6,1.3,1.1,2,1.5c0.8,0.4,1.6,0.6,2.4,0.6c1.1,0,1.9-0.2,2.5-0.7c0.6-0.5,0.9-1.1,0.9-1.9
               c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.3-0.3-0.5-0.6-0.7c-0.2-0.2-0.5-0.4-0.9-0.6c-0.3-0.2-0.7-0.3-1.1-0.5l-2.3-1
               c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.2-0.8-0.5-1.1-0.8c-0.3-0.3-0.6-0.7-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.6c0-0.6,0.1-1.2,0.4-1.7
               c0.3-0.5,0.6-1,1.1-1.4s1-0.7,1.6-0.9c0.6-0.2,1.3-0.3,2-0.3c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.5,0.9,2.1,1.5l-1.1,1.3
               c-0.5-0.5-1-0.9-1.7-1.1c-0.6-0.3-1.3-0.4-2-0.4c-0.9,0-1.6,0.2-2.2,0.6c-0.6,0.4-0.8,1-0.8,1.8c0,0.4,0.1,0.7,0.2,1
               c0.2,0.3,0.4,0.5,0.6,0.7s0.6,0.4,0.9,0.5c0.3,0.2,0.6,0.3,1,0.4l2.3,1c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.8,0.6,1.1,0.9
               c0.3,0.3,0.5,0.7,0.7,1.2c0.2,0.5,0.3,1,0.3,1.6c0,0.6-0.1,1.3-0.4,1.8c-0.3,0.6-0.6,1.1-1.1,1.5c-0.5,0.4-1.1,0.8-1.7,1
               C49.3,21.7,48.5,21.9,47.7,21.9z M55.4,21.6V5.2h2.1v14.6h7.1v1.8H55.4z M66.3,21.6V9.4h2v12.1H66.3z M67.3,7
               c-0.4,0-0.7-0.1-1-0.4C66,6.3,65.9,6,65.9,5.6c0-0.4,0.1-0.7,0.4-1c0.3-0.2,0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4
               c0.3,0.2,0.4,0.6,0.4,1c0,0.4-0.1,0.7-0.4,0.9C68,6.8,67.7,7,67.3,7z M71.4,21.6V9.4h1.7l0.2,1.7h0.1c0.6-0.6,1.2-1.1,1.8-1.5
               c0.6-0.4,1.4-0.6,2.2-0.6c1.3,0,2.2,0.4,2.8,1.2c0.6,0.8,0.9,2,0.9,3.5v7.7h-2v-7.4c0-1.1-0.2-2-0.5-2.5c-0.4-0.5-0.9-0.8-1.7-0.8
               c-0.6,0-1.2,0.2-1.7,0.5c-0.5,0.3-1,0.8-1.6,1.4v8.8H71.4z M87.8,21.9c-0.6,0-1.2-0.1-1.6-0.3c-0.4-0.2-0.8-0.5-1.1-0.8
               c-0.3-0.3-0.5-0.8-0.6-1.3c-0.1-0.5-0.2-1-0.2-1.6v-6.7h-1.8V9.6l1.9-0.1l0.2-3.4h1.7v3.4h3.3v1.7h-3.3v6.7c0,0.7,0.1,1.3,0.4,1.7
               c0.3,0.4,0.8,0.6,1.5,0.6c0.2,0,0.4,0,0.7-0.1c0.2-0.1,0.5-0.1,0.7-0.2l0.4,1.5c-0.3,0.1-0.7,0.2-1.1,0.3
               C88.5,21.8,88.1,21.9,87.8,21.9z"
            />
        </svg>
    );
};

export const EslintLogo = memo(EslintLogoInner);
