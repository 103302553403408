import React, { memo } from 'react';

const QuickIssueFaceIdInner = () => {
    return (
        <svg viewBox="0 0 22 20">
            <path d="M6.10733 0C6.565 0 6.93658 0.371539 6.93658 0.830242C6.93658 1.28786 6.56609 1.65939 6.10733 1.65939L4.33224 1.66048C2.859 1.66266 1.66035 2.86226 1.66035 4.33643V6.40223C1.66035 6.85985 1.28768 7.23247 0.83001 7.23247C0.372344 7.23247 0.000762939 6.85985 0.000762939 6.40223V4.33643C0.000762939 1.94813 1.94366 0.00326824 4.33115 0.0010891L6.10733 0ZM13.9235 0.00032711H15.6594C18.0534 0.00032711 19.9996 1.94627 19.9996 4.34003V6.40256C19.9996 6.86017 19.6291 7.2328 19.1703 7.2328C18.7127 7.2328 18.3411 6.86017 18.3411 6.40256V4.34003C18.3411 2.8615 17.1381 1.65863 15.6594 1.65863H13.9235C13.4658 1.65863 13.0943 1.28818 13.0943 0.830569C13.0943 0.371865 13.4658 0.00032711 13.9235 0.00032711ZM1.65958 12.2222H0V15.6636C0 18.0519 1.94399 19.9967 4.33148 19.9989L6.10657 20C6.56533 20 6.93582 19.6285 6.93691 19.1698C6.93691 18.7121 6.56533 18.3406 6.10766 18.3406L4.33366 18.3395C2.85932 18.3373 1.65958 17.1377 1.65958 15.6636V12.2222ZM9.79998 10.3499C11.5634 10.3499 12.9764 8.93685 12.9764 7.17463C12.9764 5.41241 11.5634 4 9.79998 4C8.03712 4 6.62359 5.41241 6.62359 7.17463C6.62359 8.93685 8.03712 10.3499 9.79998 10.3499ZM5 13.945C5 15.577 7.19742 16 9.8 16C10.629 16 11.4192 15.9582 12.1105 15.8477C12.3528 14.6021 12.9805 13.4942 13.8643 12.653C13.0064 12.0824 11.4887 11.9044 9.8 11.9044C7.21123 11.9044 5 12.3124 5 13.945ZM18.4224 18.4385C18.1724 18.6797 18.1724 19.0706 18.4224 19.3118C18.6724 19.5613 19.0776 19.5613 19.3276 19.3201L21.8103 16.933C21.8707 16.8748 21.9138 16.8083 21.9483 16.7334C21.9828 16.6585 22 16.5837 22 16.5005C22 16.4173 21.9828 16.3342 21.9483 16.2593C21.9138 16.1845 21.8707 16.1179 21.8103 16.0597L19.3276 13.6809C19.0776 13.4397 18.6724 13.4397 18.4224 13.6809C18.1724 13.9221 18.1724 14.313 18.4224 14.5542L19.8017 15.8767H15.6379C15.2845 15.8767 15 16.1512 15 16.5005C15 16.8415 15.2845 17.116 15.6379 17.116H19.8017L18.4224 18.4385Z" />
        </svg>
    );
};

export const QuickIssueFaceId = memo(QuickIssueFaceIdInner);
