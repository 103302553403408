import React, { memo } from 'react';

const GridInner = () => {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M10 3H4C3.448 3 3 3.448 3 4v6c0 .552.448 1 1 1h6c.552 0 1-.448 1-1V4C11 3.448 10.552 3 10 3zM9 9H5V5h4V9zM14 11h6c.553 0 1-.448 1-1V4c0-.552-.447-1-1-1h-6c-.553 0-1 .448-1 1v6C13 10.552 13.447 11 14 11zM15 5h4v4h-4V5zM3 20c0 .552.448 1 1 1h6c.552 0 1-.448 1-1v-6c0-.552-.448-1-1-1H4c-.552 0-1 .448-1 1V20zM5 15h4v4H5V15zM13 20c0 .552.447 1 1 1h6c.553 0 1-.448 1-1v-6c0-.552-.447-1-1-1h-6c-.553 0-1 .448-1 1V20zM15 15h4v4h-4V15z" />
        </svg>
    );
};

export const Grid = memo(GridInner);
