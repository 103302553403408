import React, { memo } from 'react';

const QuickFilterInner = () => {
    return (
        <svg viewBox="0 0 20 20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.12819 0H17.8718C19.0476 0 20 0.981053 20 2.19225V3.72376C20 4.31133 19.7704 4.87557 19.3627 5.28708L12.8577 11.867C12.7454 11.9816 12.5931 12.0452 12.4355 12.0441L6.98893 12.0272C6.82317 12.0272 6.66564 11.9561 6.55238 11.832L0.574517 5.25738C0.204891 4.85117 0 4.31451 0 3.7577V2.19332C0 0.982113 0.952381 0 2.12819 0ZM7.2801 13.8241L12.1347 13.839C12.4374 13.8401 12.6824 14.0935 12.6824 14.4043V17.1353C12.6824 17.4471 12.5053 17.7293 12.2294 17.8597L7.8227 19.9289C7.71974 19.9767 7.61061 20 7.50147 20C7.35629 20 7.21112 19.9576 7.08448 19.8738C6.86311 19.7274 6.72927 19.475 6.72927 19.2046V14.3894C6.72927 14.0766 6.97637 13.8231 7.2801 13.8241Z"
            />
        </svg>
    );
};

export const QuickFilter = memo(QuickFilterInner);
