import React, { memo } from 'react';

const GroupsInner = () => {
    return (
        <svg viewBox="0 0 21 21">
            <path d="M3 -4.59041e-06C1.36 -0.0100046 0.00999541 1.31 -4.59041e-06 2.95C-0.0100046 4.6 1.31 5.93999 2.95 5.95C3.86 5.96 4.72 5.55 5.29 4.84L9.83 7.87C10.18 7.4 10.61 7 11.11 6.69L5.94 3.24C5.95 3.16 5.96 3.09 5.96 3.01V3C5.97 1.36 4.65 0.00999541 3 -4.59041e-06ZM5.3 15.2L9.43999 12.44C9.68999 12.96 10.03 13.42 10.45 13.81L5.94 16.81C5.95 16.89 5.96 16.96 5.96 17.04V17.05C5.96 17.99 5.52 18.87 4.76 19.43C3.45 20.4 1.61 20.12 0.639995 18.81C-0.330005 17.5 -0.0500046 15.66 1.26 14.69C2.54 13.75 4.31 13.98 5.3 15.2ZM16.03 16.3L15.08 14.72C15.62 14.52 16.11 14.22 16.53 13.83L17.54 15.51C17.61 15.5 17.68 15.5 17.75 15.49C18.32 15.49 18.87 15.7 19.29 16.09C20.2 16.93 20.26 18.35 19.41 19.27C18.57 20.18 17.15 20.24 16.23 19.39C15.35 18.57 15.27 17.21 16.03 16.3ZM1.75 3C1.75 2.31 2.31 1.74 3.01 1.74C3.7 1.75 4.26 2.31 4.26 3C4.26 3.7 3.7 4.26 3 4.26C2.31 4.26 1.75 3.7 1.75 3ZM1.75 17.04C1.75 16.35 2.31 15.78 3.01 15.78C3.7 15.78 4.27 16.34 4.27 17.04C4.27 17.74 3.7 18.3 3 18.3C2.31 18.3 1.75 17.74 1.75 17.04ZM17.19 17.75C17.19 17.44 17.44 17.2 17.74 17.2C18.05 17.2 18.29 17.45 18.29 17.75C18.29 18.06 18.04 18.3 17.74 18.3C17.44 18.3 17.19 18.05 17.19 17.75ZM17 10.5C17 12.43 15.43 14 13.5 14C11.57 14 10 12.43 10 10.5C10 8.56999 11.57 7 13.5 7C15.43 7 17 8.56999 17 10.5ZM15 10.5C15 11.33 14.33 12 13.5 12C12.67 12 12 11.33 12 10.5C12 9.67 12.67 9 13.5 9C14.33 9 15 9.67 15 10.5Z" />
            <path d="M5 3C5 4.10456 4.10456 5 3 5C1.89543 5 0.999995 4.10456 0.999995 3C0.999995 1.89543 1.89543 0.999995 3 0.999995C4.10456 0.999995 5 1.89543 5 3Z" />
            <path d="M5 17C5 18.1046 4.10456 19 3 19C1.89543 19 0.999995 18.1046 0.999995 17C0.999995 15.8954 1.89543 15 3 15C4.10456 15 5 15.8954 5 17Z" />
            <path d="M19 17.5C19 18.3284 18.3284 19 17.5 19C16.6716 19 16 18.3284 16 17.5C16 16.6716 16.6716 16 17.5 16C18.3284 16 19 16.6716 19 17.5Z" />
            <path d="M17 10.5C17 12.433 15.433 14 13.5 14C11.567 14 10 12.433 10 10.5C10 8.567 11.567 7 13.5 7C15.433 7 17 8.567 17 10.5Z" />
        </svg>
    );
};

export const Groups = memo(GroupsInner);
