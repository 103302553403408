import React, { memo } from 'react';

const TimeTrackingInner = () => {
    return (
        <svg viewBox="0 0 18 20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 6.5C18 2.2 16.7 1.6 13.4 1.5V0.8C13.4 0.4 13.1 0 12.6 0C12.2 0 11.8 0.3 11.8 0.8V1.5H6V0.8C6 0.3 5.7 0 5.3 0C4.8 0 4.5 0.3 4.5 0.8V1.5C0.7 1.6 0 3 0 6.6V14.9C0 18 1.3 20 5.2 20H12.9C16.9 20 18 18.1 18 14.9V6.5ZM16.5 6.8H1.6V6.5C1.3 3.3 2.5 3.1 4.6 3V3.8C4.6 4.2 4.9 4.6 5.4 4.6C5.7 4.6 6 4.3 6 3.8V3H11.9V3.8C11.9 4.2 12.2 4.6 12.7 4.6C13.1 4.6 13.5 4.3 13.5 3.8V3C16.4 3 16.6 3.7 16.6 6.4V6.8H16.5ZM8.01385 14.8007C8.15402 14.9354 8.33587 15.0018 8.51772 15C8.69957 14.999 8.88047 14.9308 9.01875 14.7942L12.687 11.1779C12.9626 10.9057 12.9598 10.4684 12.6813 10.2C12.4019 9.93151 11.952 9.93336 11.6764 10.2055L8.5073 13.3292L7.20974 12.082C6.93033 11.8135 6.48139 11.8163 6.20483 12.0884C5.92922 12.3606 5.93206 12.7979 6.21146 13.0663L8.01385 14.8007Z"
            />
        </svg>
    );
};

export const TimeTracking = memo(TimeTrackingInner);
