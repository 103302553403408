import React, { memo } from 'react';

const ControlInner = () => {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M18.618,7.462L6.403,2.085C6.158,1.977,5.882,1.973,5.633,2.069C5.385,2.168,5.186,2.36,5.081,2.606l-3,7 c-0.218,0.507,0.018,1.095,0.525,1.313L9.563,13.9L8.323,17H4v-3H2v4v4h2v-3h4.323c0.823,0,1.552-0.494,1.856-1.258l1.222-3.054 l3.419,1.465c0.506,0.217,1.091-0.015,1.311-0.518l3-6.857C19.352,8.272,19.122,7.685,18.618,7.462z" />
            <path transform="rotate(-158.218 20 13.5)" d="M19 10.807H21V16.192H19z" />
        </svg>
    );
};

export const Control = memo(ControlInner);
