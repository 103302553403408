import React, { memo } from 'react';

const ExportXlsInner = () => {
    return (
        <svg viewBox="0 0 27 32">
            <path
                d="M25.8,7.7l-3.9-4c-0.5-0.6-1.3-0.8-2-0.8h-3.3l1.3,1.3h1.5c0.5,0.1,0.8,0.5,0.8,1.1v0.2v1v1.7c0,0.8,0.7,1.4,1.5,1.4h1.2
	h0.9H24c0.6,0,1,0.5,1.2,1.1v18.7c0,0.7-0.6,1.4-1.3,1.4h-18c-0.7,0-1.5-0.7-1.5-1.4V29H3.1v0.2c0,1.5,1.3,2.8,2.8,2.8h18
	c1.5,0,2.7-1.3,2.7-2.8V9.6C26.5,8.9,26.3,8.2,25.8,7.7z"
            />
            <path d="M4.4,4.1c0-0.7,0.7-1.3,1.5-1.3h0.9l1.3-1.3H5.9c-1.5,0-2.8,1.1-2.8,2.6v10.6h1.3V4.1z" />
            <path
                d="M22.9,16H0.9c-0.5,0-1,0.4-1,1v9.8c0,0.5,0.4,1,1,1h21.9c0.5,0,1-0.4,1-1v-9.8C23.8,16.4,23.4,16,22.9,16z M7.8,24.9l-1-2.1
	l-1,2.1H4l1.8-3.1l-1.7-3.1h1.7l1,2.1l1-2.1h1.7l-1.7,3.1l1.8,3.1H7.8z M14.5,24.9h-4.1v-6.2H12v5h2.6V24.9z M17.3,20.9
	c0.2,0.1,0.5,0.3,0.9,0.4c0.4,0.1,0.8,0.3,1.1,0.4c0.7,0.4,1,0.9,1,1.6c0,0.5-0.2,1-0.6,1.3c-0.4,0.3-1,0.5-1.7,0.5
	c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.7-0.3-1.1h1.5c0,0.3,0.1,0.6,0.3,0.7c0.2,0.2,0.5,0.2,0.8,0.2
	c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.8-0.4c-0.4-0.1-0.7-0.3-0.9-0.4
	c-0.8-0.4-1.2-0.9-1.2-1.6c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.5-0.5,0.8-0.6c0.4-0.1,0.8-0.2,1.2-0.2c0.4,0,0.8,0.1,1.2,0.2
	c0.4,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.3,0.6,0.3,1h-1.5c0-0.3-0.1-0.4-0.2-0.6c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.6,0.2
	c-0.2,0.1-0.2,0.3-0.2,0.4C17.1,20.6,17.2,20.7,17.3,20.9z"
            />
            <path
                d="M6,6.6c0.1,0.2,0.2,0.3,0.4,0.3h2.2v0.9c0,4.1,3.3,7.4,7.4,7.4c2.4,0,4.5-1.1,5.8-2.9h-1.1c0,0-0.1,0-0.1,0
	c-2.5,0-4.6-2-4.6-4.5c0,0,0-0.4,0-0.9h2.3c0.2,0,0.3-0.1,0.4-0.3c0.1-0.2,0-0.4-0.1-0.5l-5.9-6C12.6,0.1,12.5,0,12.4,0
	c-0.1,0-0.2,0-0.3,0.1l-5.9,6C6,6.3,5.9,6.5,6,6.6z"
            />
        </svg>
    );
};

export const ExportXls = memo(ExportXlsInner);
