import React, { memo } from 'react';

const CalendarIconInner = () => {
    return (
        <svg viewBox="0 0 14 16">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4222 1.2C12.9889 1.28 14 1.76 14 5.2V11.92C14 14.48 13.1444 16 10.0333 16H4.04444C1.01111 16 0 14.4 0 11.92V5.28C0 2.4 0.544444 1.28 3.5 1.2V0.64C3.5 0.24 3.73333 0 4.12222 0C4.43333 0 4.66667 0.24 4.66667 0.64V1.2H9.17778V0.64C9.17778 0.24 9.48889 0 9.8 0C10.1889 0 10.4222 0.32 10.4222 0.64V1.2ZM1.24444 5.44H12.8333H12.9111V5.12C12.9111 2.96 12.7556 2.4 10.5 2.4V3.04C10.5 3.44 10.1889 3.68 9.87778 3.68C9.48889 3.68 9.25556 3.36 9.25556 3.04V2.4H4.66667V3.04C4.66667 3.44 4.43333 3.68 4.2 3.68C3.81111 3.68 3.57778 3.36 3.57778 3.04V2.4C1.94444 2.48 1.01111 2.64 1.24444 5.2V5.44Z"
            />
        </svg>
    );
};

export const CalendarIcon = memo(CalendarIconInner);
