import React, { memo } from 'react';

export const LodashLogoInner = () => {
    return (
        <svg viewBox="0 0 50 45">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.0749002 0H4.66992V30.4943H22.8412V34.2538H0.074707V0H0.0749002ZM35.373 8.98125C38.506 8.56351 41.848 9.19003 44.3543 11.2788C47.0697 13.1585 48.5317 16.5003 48.7403 19.6333C49.1583 23.3928 48.7403 27.1525 46.6518 30.2855C44.772 32.7918 41.848 34.4628 38.506 34.6716C35.373 35.0893 31.8225 34.4628 29.1072 32.3741C26.6007 30.2855 25.3475 27.1525 24.9297 24.0195C24.5122 20.6776 24.9297 16.918 27.0185 13.994C28.6895 11.07 32.0312 9.19003 35.373 8.98125ZM35.9997 12.532C38.0883 12.323 40.3858 12.9496 42.0568 14.4118C43.5188 16.0826 44.3543 18.1713 44.5632 20.2598C44.772 22.5575 44.5632 25.0638 43.5188 27.1525C42.4745 29.2411 40.5947 30.7033 38.2972 31.1208C36.2085 31.5386 33.9112 30.9121 32.449 29.6588C30.778 28.4056 29.7337 26.5258 29.316 24.6461C28.8982 21.9308 29.1072 19.2155 30.1515 16.7093C31.1957 14.4118 33.4933 12.7408 35.9995 12.5318L35.9997 12.532Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.0748291 39.6844H49.367V44.9061H0.0748291V39.6844Z"
                fill="black"
            />
        </svg>
    );
};

export const LodashLogo = memo(LodashLogoInner);
