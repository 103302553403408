import React, { memo } from 'react';

const KeyInner = () => {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M7,17c2.414,0,4.435-1.721,4.898-4H14v2h2v-2h2v3h2v-3h1v-2h-9.102C11.435,8.721,9.414,7,7,7c-2.757,0-5,2.243-5,5 S4.243,17,7,17z M7,9c1.654,0,3,1.346,3,3s-1.346,3-3,3s-3-1.346-3-3S5.346,9,7,9z" />
        </svg>
    );
};

export const Key = memo(KeyInner);
