import React, { memo } from 'react';

const ViewInner = () => {
    return (
        <svg viewBox="0 0 32 32">
            <path d="M0,6.8c0,1.1,0.9,2,2,2h4.8c1.1,0,2-0.9,2-2V2.1c0-1.1-0.9-2-2-2H2c-1.1,0-2,0.9-2,2V6.8z" />
            <path d="M0,18.4c0,1.1,0.9,2,2,2h4.8c1.1,0,2-0.9,2-2v-4.8c0-1.1-0.9-2-2-2H2c-1.1,0-2,0.9-2,2V18.4z" />
            <path d="M0,29.9c0,1.1,0.9,2,2,2h4.8c1.1,0,2-0.9,2-2v-4.8c0-1.1-0.9-2-2-2H2c-1.1,0-2,0.9-2,2V29.9z" />
            <path d="M11.6,6.9c0,1.1,0.9,2,2,2h4.8c1.1,0,2-0.9,2-2V2.1c0-1.1-0.9-2-2-2h-4.8c-1.1,0-2,0.9-2,2V6.9z" />
            <path d="M11.6,18.4c0,1.1,0.9,2,2,2h4.8c1.1,0,2-0.9,2-2v-4.8c0-1.1-0.9-2-2-2h-4.8c-1.1,0-2,0.9-2,2V18.4z" />
            <path d="M11.6,30c0,1.1,0.9,2,2,2h4.8c1.1,0,2-0.9,2-2v-4.8c0-1.1-0.9-2-2-2h-4.8c-1.1,0-2,0.9-2,2V30z" />
            <path d="M23.2,6.8c0,1.1,0.9,2,2,2H30c1.1,0,2-0.9,2-2V2c0-1.1-0.9-2-2-2h-4.8c-1.1,0-2,0.9-2,2V6.8z" />
            <path d="M23.2,18.3c0,1.1,0.9,2,2,2H30c1.1,0,2-0.9,2-2v-4.8c0-1.1-0.9-2-2-2h-4.8c-1.1,0-2,0.9-2,2V18.3z" />
            <path d="M23.2,30c0,1.1,0.9,2,2,2H30c1.1,0,2-0.9,2-2v-4.8c0-1.1-0.9-2-2-2h-4.8c-1.1,0-2,0.9-2,2V30z" />
        </svg>
    );
};

export const View = memo(ViewInner);
