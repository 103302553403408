import React, { memo } from 'react';

const CentralPostInner = () => {
    return (
        <svg viewBox="0 0 18 18">
            <path d="M9 5.52093C7.07856 5.52093 5.52093 7.07857 5.52093 9.00001C5.52093 10.9214 7.07856 12.4791 9 12.4791C10.9214 12.4791 12.4791 10.9214 12.4791 9.00001C12.4791 7.07857 10.9214 5.52093 9 5.52093Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5395 9.00001C17.5395 6.98371 16.8407 5.13062 15.6721 3.66973L17.7221 1.61977C18.0926 1.24922 18.0926 0.648453 17.7221 0.277908C17.3515 -0.0926361 16.7508 -0.0926362 16.3802 0.277908L14.3303 2.32787C12.8694 1.15925 11.0163 0.460459 9 0.460459C6.98371 0.460459 5.13062 1.15925 3.66972 2.32787L1.61977 0.277914C1.24923 -0.0926308 0.648454 -0.0926308 0.27791 0.277914C-0.0926346 0.648458 -0.0926346 1.24923 0.27791 1.61977L2.32786 3.66973C1.15925 5.13063 0.460454 6.98371 0.460454 9.00001C0.460454 11.0163 1.15925 12.8694 2.32786 14.3303L0.277908 16.3802C-0.0926361 16.7508 -0.0926361 17.3515 0.277908 17.7221C0.648453 18.0926 1.24922 18.0926 1.61977 17.7221L3.66972 15.6721C5.13062 16.8408 6.9837 17.5396 9 17.5396C11.0163 17.5396 12.8694 16.8408 14.3303 15.6721L16.3802 17.7221C16.7508 18.0926 17.3515 18.0926 17.7221 17.7221C18.0926 17.3515 18.0926 16.7508 17.7221 16.3802L15.6721 14.3303C16.8407 12.8694 17.5395 11.0163 17.5395 9.00001ZM4.30302 4.30397C5.50499 3.10175 7.16566 2.35814 9 2.35814C12.6682 2.35814 15.6419 5.3318 15.6419 9.00001C15.6419 10.8329 14.8994 12.4924 13.6989 13.6941L13.6965 13.6965L13.6943 13.6987C12.4926 14.8994 10.833 15.6419 9 15.6419C7.16715 15.6419 5.5077 14.8995 4.30595 13.699C3.10256 12.4968 2.35813 10.8354 2.35813 9.00001C2.35813 7.16614 3.10135 5.50587 4.30302 4.30397Z"
            />
        </svg>
    );
};

export const CentralPost = memo(CentralPostInner);
