import React from 'react';

const Restore = () => (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 3.97696C18 3.56596 17.6761 3.24297 17.2871 3.24297H14.3714C13.7781 3.24297 13.2627 2.82197 13.1304 2.22698L12.967 1.49699C12.7385 0.616994 11.9488 0 11.0647 0H6.93527C6.0415 0 5.26054 0.616994 5.02323 1.54598L4.87054 2.22798C4.7373 2.82197 4.22185 3.24297 3.62957 3.24297H0.713853C0.32386 3.24297 0 3.56596 0 3.97696V4.35696C0 4.75795 0.32386 5.09095 0.713853 5.09095H17.2871C17.6761 5.09095 18 4.75795 18 4.35696V3.97696ZM16.6432 7.48913C16.6432 7.55712 16.1102 14.2971 15.8058 17.134C15.6152 18.875 14.4929 19.932 12.8094 19.961C11.5149 19.99 10.2496 20 9.00379 20C7.68112 20 6.38763 19.99 5.13206 19.961C3.50498 19.922 2.38168 18.846 2.20079 17.134C1.94517 14.8102 1.54958 9.89917 1.40755 8.13594C1.37558 7.73906 1.35646 7.50162 1.35467 7.48913C1.34494 7.28313 1.41108 7.08813 1.54529 6.93113C1.67756 6.78413 1.86818 6.69713 2.06852 6.69713H15.9391C16.1384 6.69713 16.3193 6.78413 16.4623 6.93113C16.5955 7.08813 16.6626 7.28313 16.6432 7.48913ZM11.3497 14.2851C11.4848 14.0249 11.7459 13.8644 12.0303 13.8644C12.2634 13.8644 12.4779 13.9728 12.6037 14.155C12.7249 14.3285 12.7436 14.5366 12.655 14.7275C12.0117 16.1066 10.5804 17 9.00466 17C6.79487 17 5 15.2782 5 13.1618C5 11.0454 6.79487 9.32364 9.00466 9.32364C9.89511 9.32364 10.7389 9.60554 11.4382 10.1216L11.3776 9.7183C11.331 9.39737 11.5734 9.08078 11.9138 9.01138C12.0816 8.98103 12.2541 9.01138 12.3939 9.10246C12.5338 9.19353 12.6224 9.33232 12.6457 9.49278L13 11.9345L10.4079 12.4419C10.2401 12.4722 10.0676 12.4419 9.92774 12.3508C9.78788 12.2597 9.6993 12.121 9.67599 11.9605C9.62937 11.6396 9.8718 11.323 10.2121 11.2536L10.5944 11.1755C10.1375 10.8286 9.58275 10.6334 9.00466 10.6334C7.55944 10.6334 6.37995 11.7653 6.37995 13.1575C6.37995 14.5496 7.55478 15.6816 9.00466 15.6816C10.0023 15.6816 10.9021 15.1438 11.3497 14.2851Z"
            fill="#293440"
        />
    </svg>
);

export default Restore;
