import React, { memo } from 'react';

const EnterEventInner = () => {
    return (
        <svg viewBox="0 0 15 16">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.38859 0H11.3555C13.3389 0 14.9565 1.6 14.9565 3.552V12.448C14.9565 14.408 13.3389 16 11.3392 16H7.38046C5.39703 16 3.77127 14.408 3.77127 12.456V8.616H8.81114L7.51052 9.896C7.26666 10.136 7.26666 10.528 7.51052 10.768C7.63246 10.888 7.79503 10.944 7.95761 10.944C8.11206 10.944 8.27463 10.888 8.39657 10.768L10.7702 8.44C10.8921 8.328 10.9571 8.168 10.9571 8C10.9571 7.84 10.8921 7.68 10.7702 7.568L8.39657 5.24C8.1527 5 7.75439 5 7.51052 5.24C7.26666 5.48 7.26666 5.872 7.51052 6.112L8.81114 7.384H3.77127V3.56C3.77127 1.6 5.39703 0 7.38859 0ZM0 7.99992C0 7.66392 0.279631 7.38392 0.615189 7.38392H3.77103V8.61592H0.615189C0.279631 8.61592 0 8.34392 0 7.99992Z"
            />
        </svg>
    );
};

export const EnterEvent = memo(EnterEventInner);
