import React, { memo } from 'react';

const SettingsInner = () => {
    return (
        <svg viewBox="0 0 32 32">
            <path d="M0.708062 6.62432H6.33884V9.21796C6.33884 9.56846 6.60858 9.8839 6.97947 9.8839H11.1267C11.4639 9.8839 11.7673 9.60351 11.7673 9.21796V0.665936C11.7673 0.315443 11.4976 0 11.1267 0H7.01319C6.67601 0 6.37256 0.280394 6.37256 0.665936V3.25958H0.708062C0.303455 3.25958 0 3.61008 0 3.99562V5.85323C0 6.30887 0.337172 6.62432 0.708062 6.62432Z" />
            <path d="M31.3233 3.25958H13.8241V6.62431H31.2896C31.6942 6.62431 31.9977 6.27382 31.9977 5.88828V4.03067C32.0314 3.57503 31.6942 3.25958 31.3233 3.25958Z" />
            <path d="M31.3233 14.3351H25.0182V17.6999H31.2896C31.6942 17.6999 31.9977 17.3494 31.9977 16.9638V15.1062C32.0314 14.6506 31.6942 14.3351 31.3233 14.3351Z" />
            <path d="M0.708062 17.6999H17.533V20.2935C17.533 20.644 17.8027 20.9595 18.1736 20.9595H22.3208C22.658 20.9595 22.9614 20.6791 22.9614 20.2935V11.7415C22.9614 11.391 22.6917 11.0756 22.3208 11.0756H18.2073C17.8701 11.0756 17.5667 11.356 17.5667 11.7415V14.3351H0.708062C0.303455 14.3351 0 14.6856 0 15.0712V16.9288C0 17.3844 0.337172 17.6999 0.708062 17.6999Z" />
            <path d="M31.3233 25.4107H13.8241V28.7755H31.2896C31.6942 28.7755 31.9977 28.425 31.9977 28.0394V26.1818C32.0314 25.7612 31.6942 25.4107 31.3233 25.4107Z" />
            <path d="M11.1267 22.1512H7.01319C6.67601 22.1512 6.37256 22.4315 6.37256 22.8171V25.4107H0.708062C0.303455 25.4107 0 25.7612 0 26.1468V28.0044C0 28.425 0.337172 28.7404 0.708062 28.7404H6.33884V31.3341C6.33884 31.6846 6.60858 32 6.97947 32H11.1267C11.4639 32 11.7673 31.7196 11.7673 31.3341V22.8171C11.7673 22.4315 11.4976 22.1512 11.1267 22.1512Z" />
        </svg>
    );
};

export const Settings = memo(SettingsInner);
