import React, { useState, useEffect, memo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { State } from 'redux/store';
import {
    requestDeviceControllersTypes,
    setupWizardDevicesControllerType,
    requestDeviceСonnectionsTypes,
    setupWizardDevicesConnectionType,
    setupWizardDevicesPointProtocol,
    setupWizardDevicesPointIp,
    setupWizardDevicesPointMask,
    setupWizardDevicesPointServerPort,
    setupWizardDevicesPointClientPort,
    setupWizardDevicesPointPort,
    setupWizardDevicesPointSpeed,
    setupWizardDevicesPointParity,
    setupWizardDevicesPointBit,
    setupWizardDevicesPointStopBit,
    setupWizardDevicesPointSerialNumber
} from 'redux/SetupWizard/SetupWizardDevices/setupWizardDevicesActions';
import { Inputs } from 'ui/components/Inputs/Inputs';
import { Buttons } from 'ui/components/Buttons/Buttons';
import { СheckMark } from 'assets/images/svgr/check-mark';
import { ModalWarning } from 'assets/images/svgr/modal-warning';
import './AccessPointsSetup.scss';

const AccessPointsSetupInner = () => {
    const dispatch = useDispatch();
    const { type, accessPoint, controllers, connections } = useSelector(
        (state: State) => state.setupWizard.setupWizardEquipments,
        shallowEqual
    );

    const initialState = {
        ip: '',
        mask: '',
        serverPort: '',
        clientPort: '',
        protocol: ['TCP', 'UDP'],
        portList: ['COM1', 'COM2'],
        speedList: ['9600', '19200', '38400', '57600', '115200'],
        parityList: ['Да', 'Нет'],
        bitList: ['7', '8'],
        stopBitList: ['1', '2']
    };
    const [accessPointSettings, setAccessPointSettings] = useState(initialState);
    const [controllerIsFinfd, setControllerIsFinfd] = useState(false);
    const [message, setMessage] = useState(false);

    useEffect(() => {
        if (type) {
            dispatch(requestDeviceControllersTypes(type.id));
        }
    }, [dispatch, type]);

    useEffect(() => {
        if (!controllers.controllerType && controllers.controllerTypes.length > 0) {
            dispatch(setupWizardDevicesControllerType(controllers.controllerTypes[0]));
        } else if (controllers.controllerType) {
            dispatch(requestDeviceСonnectionsTypes(controllers.controllerType.id));
        }
    }, [dispatch, controllers.controllerTypes, controllers.controllerType]);

    useEffect(() => {
        if (connections.connectionTypes.length > 0) {
            dispatch(setupWizardDevicesConnectionType(connections.connectionTypes[0]));
        }
    }, [dispatch, connections.connectionTypes]);

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if (e.target.name === 'accessPointsControllerType') {
            const selectedObjId = findIndexFuncName(controllers.controllerTypes, e.target.value);

            dispatch(setupWizardDevicesControllerType(controllers.controllerTypes[selectedObjId]));
        } else if (e.target.name === 'accessPointType') {
            const selectedObjId = findIndexFuncName(connections.connectionTypes, e.target.value);

            dispatch(setupWizardDevicesConnectionType(connections.connectionTypes[selectedObjId]));
        } else if (e.target.name === 'accessPointIp') {
            const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})/);

            if (x) {
                e.target.value = x[1] + (x[2] ? '.' + x[2] : '') + (x[3] ? '.' + x[3] : '') + (x[4] ? '.' + x[4] : '');

                setAccessPointSettings({ ...accessPointSettings, ip: e.target.value });
            }

            if (
                /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                    e.target.value
                )
            ) {
                dispatch(setupWizardDevicesPointIp(e.target.value));
            } else {
                dispatch(setupWizardDevicesPointIp(''));
            }
        } else if (e.target.name === 'accessPointsMask') {
            const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})/);

            if (x) {
                e.target.value = x[1] + (x[2] ? '.' + x[2] : '') + (x[3] ? '.' + x[3] : '') + (x[4] ? '.' + x[4] : '');

                setAccessPointSettings({ ...accessPointSettings, mask: e.target.value });
            }

            if (
                /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                    e.target.value
                )
            ) {
                dispatch(setupWizardDevicesPointMask(e.target.value));
            } else {
                dispatch(setupWizardDevicesPointMask(''));
            }
        } else if (e.target.name === 'accessPointsServerPort') {
            if (e.target.value === '') {
                if (/^[0-9]{1,5}$/.test(e.target.value)) {
                    dispatch(setupWizardDevicesPointServerPort(e.target.value));
                } else {
                    dispatch(setupWizardDevicesPointServerPort(''));
                }
            }
        } else if (e.target.name === 'accessPointsClientPort') {
            dispatch(setupWizardDevicesPointClientPort(''));
        }
    };

    const findIndexFuncName = (arr: any[], index: any) => {
        return arr.findIndex((elem) => {
            const searchingElem = arr.find((elem) => elem.name === index);

            return elem === searchingElem;
        });
    };

    const findController = () => {
        setTimeout(() => {
            const random = Math.floor(Math.random() * Math.floor(2));

            if (random === 1) {
                dispatch(setupWizardDevicesPointSerialNumber(`${Math.floor(Math.random() * 10000000000)}`));
                setControllerIsFinfd(true);
                setMessage(true);
            } else {
                dispatch(setupWizardDevicesPointSerialNumber(''));
                setControllerIsFinfd(true);
                setMessage(false);
            }
        }, 2000);
    };

    const connectionSettingsIsFilling = () => {
        if (connections.connectionType) {
            if (connections.connectionType.name === 'Ethernet') {
                if (
                    accessPoint.protocol.length > 0 &&
                    accessPoint.ip.length > 0 &&
                    accessPoint.mask.length > 0 &&
                    accessPoint.serverPort.length > 0 &&
                    accessPoint.clientPort.length > 0
                ) {
                    return true;
                } else {
                    return false;
                }
            } else if (connections.connectionType.name === 'RS-485') {
                if (
                    accessPoint.port.length > 0 &&
                    accessPoint.speed.length > 0 &&
                    accessPoint.parity.length > 0 &&
                    accessPoint.bit.length > 0 &&
                    accessPoint.stopBit.length > 0
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    return (
        <div className="access-points-setup">
            <div className="access-points-setup__title">
                <div className="p--md--bold">Выберите и настройте точку доступа</div>
            </div>

            <div className="access-points-setup__body">
                <div className="device__setup">
                    <div className="device-settings__header">
                        <Inputs
                            label="Тип контроллера"
                            type="text"
                            name="accessPointsControllerType"
                            placeholder="Выберите тип"
                            onInputChange={(e) => inputHandler(e)}
                            value={controllers.controllerType ? controllers.controllerType.name : ''}
                            list={controllers.controllerTypes ? controllers.controllerTypes.map((elem) => elem.name) : []}
                        />

                        <Inputs
                            label="Тип подключения"
                            type="text"
                            name="accessPointType"
                            onInputChange={(e) => inputHandler(e)}
                            value={connections.connectionType ? connections.connectionType.name : ''}
                            list={connections.connectionTypes ? connections.connectionTypes.map((elem) => elem.name) : []}
                        />
                    </div>

                    <div className="device-settings__body">
                        <div className="device-settings__body-top">
                            {connections.connectionType && connections.connectionType.name === 'Ethernet' && (
                                <>
                                    <Inputs
                                        label="Тип протокола"
                                        type="text"
                                        name="accessPointProtocol"
                                        onInputChange={(e) => {
                                            dispatch(setupWizardDevicesPointProtocol(e.target.value));
                                        }}
                                        value={accessPoint.protocol}
                                        list={accessPointSettings.protocol}
                                    />

                                    <Inputs
                                        label="IP-адрес"
                                        type="text"
                                        name="accessPointIp"
                                        onInputChange={(e) => inputHandler(e)}
                                        value={accessPointSettings.ip}
                                        validation="^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                                        validationTitle="Формат ввода данных ХХХ.ХХХ.ХХХ.ХХХ"
                                    />
                                </>
                            )}

                            {connections.connectionType && connections.connectionType.name === 'RS-485' && (
                                <>
                                    <div className="port-settings">
                                        <Inputs
                                            label="Порт"
                                            type="text"
                                            name="eaccessPointPort"
                                            onInputChange={(e) => {
                                                dispatch(setupWizardDevicesPointPort(e.target.value));
                                            }}
                                            value={accessPoint.port}
                                            list={accessPointSettings.portList}
                                        />

                                        <Buttons name="Refresh" size="m" typical onPress={() => console.log('Press')} />
                                    </div>

                                    <Inputs
                                        label="Скорость"
                                        type="text"
                                        name="accessPointSpeed"
                                        onInputChange={(e) => {
                                            dispatch(setupWizardDevicesPointSpeed(e.target.value));
                                        }}
                                        value={accessPoint.speed}
                                        list={accessPointSettings.speedList}
                                    />
                                </>
                            )}
                        </div>

                        <div className="device-settings__body-bottom">
                            {connections.connectionType && connections.connectionType.name === 'Ethernet' && (
                                <>
                                    <Inputs
                                        label="Маска подсети"
                                        type="text"
                                        name="accessPointsMask"
                                        onInputChange={(e) => inputHandler(e)}
                                        value={accessPointSettings.mask}
                                        validation="^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                                        validationTitle="Формат ввода данных ХХХ.ХХХ.ХХХ.ХХХ"
                                    />

                                    <Inputs
                                        label="Порт сервера"
                                        type="text"
                                        name="accessPointsServerPort"
                                        onInputChange={(e) => inputHandler(e)}
                                        value={accessPointSettings.serverPort}
                                    />

                                    <Inputs
                                        label="Порт клиента"
                                        type="text"
                                        name="accessPointsClientPort"
                                        onInputChange={(e) => inputHandler(e)}
                                        value={accessPointSettings.clientPort}
                                    />
                                </>
                            )}

                            {connections.connectionType && connections.connectionType.name === 'RS-485' && (
                                <>
                                    <Inputs
                                        label="Четность"
                                        type="text"
                                        name="accessPointsParity"
                                        onInputChange={(e) => {
                                            dispatch(setupWizardDevicesPointParity(e.target.value));
                                        }}
                                        value={accessPoint.parity}
                                        list={accessPointSettings.parityList}
                                    />

                                    <Inputs
                                        label="Биты данных"
                                        type="text"
                                        name="accessPointsBits"
                                        onInputChange={(e) => {
                                            dispatch(setupWizardDevicesPointBit(e.target.value));
                                        }}
                                        value={accessPoint.bit}
                                        list={accessPointSettings.bitList}
                                    />

                                    <Inputs
                                        label="Стоповые биты"
                                        type="text"
                                        name="accessPointsStopBits"
                                        onInputChange={(e) => {
                                            dispatch(setupWizardDevicesPointStopBit(e.target.value));
                                        }}
                                        value={accessPoint.stopBit}
                                        list={accessPointSettings.stopBitList}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div className="device-settings__footer">
                        <Buttons
                            name="Setup"
                            label="Проверить связь"
                            typical
                            disable={!connectionSettingsIsFilling()}
                            onPress={!connectionSettingsIsFilling() ? findController : undefined}
                        />
                    </div>
                </div>

                <div className="device__info">
                    {!controllerIsFinfd && (
                        <div className="access-points-type__description">
                            <div className="p--sm--normal">Выберите тип контроллера и тип подключения, чтобы продолжить настройку</div>

                            <div className="p--sm--normal">Следуйте указаниям мастера для завершения процесса настройки</div>
                        </div>
                    )}

                    {controllerIsFinfd && (
                        <div className="customization-tab__footer-message">
                            <div className={`customization-tab__footer-message-icon ${message ? 'icon--success' : 'icon--warning'}`}>
                                {message ? <СheckMark /> : <ModalWarning />}
                            </div>

                            <div className="customization-tab__footer-message-label">
                                <div className="p--sm--normal">
                                    {message ? 'Тип контроллера успешно определен.' : 'Определить контроллер не удалось.'}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export const AccessPointsSetup = memo(AccessPointsSetupInner);
