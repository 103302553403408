import React, { memo } from 'react';

const ScanFaceInner = () => {
    return (
        <svg viewBox="0 0 20 20">
            <path d="M0 1.11111V6.66667H2.22222V2.22222H6.66667V0H1.11111C0.444444 0 0 0.444444 0 1.11111ZM20 6.66667V1.11111C20 0.444444 19.5556 0 18.8889 0H13.3333V2.22222H17.7778V6.66667H20ZM17.7778 17.7778H13.3333V20H18.8889C19.5556 20 20 19.5556 20 18.8889V13.3333H17.7778V17.7778ZM6.66667 20V17.7778H2.22222V13.3333H0V18.8889C0 19.5556 0.444444 20 1.11111 20H6.66667Z" />
            <path d="M10 3C7.90007 3 6.15012 4.63333 6.15012 6.61667V7.2C6.15012 9.18333 7.90007 10.8167 10 10.8167C12.0999 10.8167 13.8499 9.18333 13.8499 7.2V6.61667C13.8499 4.63333 12.0999 3 10 3ZM10 12.6833C7.43342 12.6833 4.16685 13.9667 3.23355 15.1333C2.65023 15.95 3.23355 17 4.16685 17H15.8331C16.7665 17 17.3498 15.95 16.7664 15.25C15.8331 14.0833 12.5666 12.6833 10 12.6833Z" />
        </svg>
    );
};

export const ScanFace = memo(ScanFaceInner);
