import React, { memo } from 'react';

const RfidCardInner = () => {
    return (
        <svg viewBox="0 0 22 19">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3853 0.249756H5.03167C2.30938 0.249756 0.527663 2.03492 0.27936 4.63828C0.346856 4.61833 0.418318 4.60763 0.49228 4.60763L20.9247 4.60763C20.9991 4.60763 21.0709 4.61845 21.1387 4.63861C20.8915 2.03507 19.1158 0.249756 16.3853 0.249756ZM0.249512 7.56671V6.06746C0.325654 6.0935 0.407317 6.10763 0.49228 6.10763L20.9247 6.10763C21.01 6.10763 21.092 6.09338 21.1684 6.06714V7.56704C21.092 7.54079 21.01 7.52655 20.9247 7.52655L0.49228 7.52655C0.407317 7.52655 0.325654 7.54067 0.249512 7.56671ZM0.249512 8.98638V13.2234C0.249512 16.1686 2.08357 18.2498 5.03167 18.2498H16.3843C19.3334 18.2498 21.1684 16.1686 21.1684 13.2234V8.98606C21.092 9.0123 21.01 9.02655 20.9247 9.02655L0.49228 9.02655C0.407317 9.02655 0.325654 9.01242 0.249512 8.98638Z"
            />
        </svg>
    );
};

export const RfidCard = memo(RfidCardInner);
