import React, { memo } from 'react';

const MonitoringInner = () => {
    return (
        <svg viewBox="0 0 18 20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2C0 0.895431 0.895431 0 2 0H16C17.1046 0 18 0.895431 18 2V14C18 15.1046 17.1046 16 16 16H11V17.75H13C13.4142 17.75 13.75 18.0858 13.75 18.5C13.75 18.9142 13.4142 19.25 13 19.25H5C4.58579 19.25 4.25 18.9142 4.25 18.5C4.25 18.0858 4.58579 17.75 5 17.75H7V16H2C0.89543 16 0 15.1046 0 14V2ZM4.5 5.25C4.91421 5.25 5.25 5.58579 5.25 6V11C5.25 11.4142 4.91421 11.75 4.5 11.75C4.08579 11.75 3.75 11.4142 3.75 11V6C3.75 5.58579 4.08579 5.25 4.5 5.25ZM14.25 6C14.25 5.58579 13.9142 5.25 13.5 5.25C13.0858 5.25 12.75 5.58579 12.75 6V11C12.75 11.4142 13.0858 11.75 13.5 11.75C13.9142 11.75 14.25 11.4142 14.25 11V6ZM7.5 3.25C7.91421 3.25 8.25 3.58579 8.25 4V11C8.25 11.4142 7.91421 11.75 7.5 11.75C7.08579 11.75 6.75 11.4142 6.75 11V4C6.75 3.58579 7.08579 3.25 7.5 3.25ZM11.25 9C11.25 8.58579 10.9142 8.25 10.5 8.25C10.0858 8.25 9.75 8.58579 9.75 9V11C9.75 11.4142 10.0858 11.75 10.5 11.75C10.9142 11.75 11.25 11.4142 11.25 11V9Z"
            />
        </svg>
    );
};

export const Monitoring = memo(MonitoringInner);
