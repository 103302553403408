import React, { memo } from 'react';

const Qr2DInner = () => {
    return (
        <svg viewBox="0 0 22 18">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.4277 0C7.8477 0 8.1887 0.334385 8.1887 0.747218C8.1887 1.15907 7.8487 1.49345 7.4277 1.49345L5.7987 1.49444C4.4467 1.4964 3.3467 2.57604 3.3467 3.90279V5.76201C3.3467 6.17386 3.0047 6.50923 2.5847 6.50923C2.1647 6.50923 1.8237 6.17386 1.8237 5.76201V3.90279C1.8237 1.75331 3.6067 0.0029418 5.7977 0.0009806L7.4267 0H7.4277ZM14.6006 0.00029418H16.1936C18.3906 0.00029418 20.1766 1.75165 20.1766 3.90603V5.7623C20.1766 6.17415 19.8366 6.50952 19.4156 6.50952C18.9956 6.50952 18.6546 6.17415 18.6546 5.7623V3.90603C18.6546 2.57535 17.5506 1.49277 16.1936 1.49277H14.6006C14.1806 1.49277 13.8396 1.15936 13.8396 0.747512C13.8396 0.334679 14.1806 0.00029418 14.6006 0.00029418ZM13.7625 3.74266H8.2385C6.9745 3.75541 5.9585 4.76837 5.9695 6.00883V7.25419C5.9725 7.39932 6.0915 7.51797 6.2395 7.52287H15.7595C15.9085 7.51895 16.0275 7.4003 16.0325 7.25419V6.00883C16.0335 5.40968 15.7975 4.83407 15.3715 4.40554C14.9485 3.98094 14.3675 3.74266 13.7625 3.74266ZM0.762 9.04123H21.239C21.659 9.04123 22 9.37562 22 9.78845C22 10.2003 21.659 10.5337 21.239 10.5337H20.177V14.0933C20.177 16.2486 18.39 18 16.194 18H14.601C14.18 18 13.839 17.6656 13.839 17.2528C13.839 16.8409 14.18 16.5065 14.601 16.5065H16.194C17.551 16.5065 18.655 15.4249 18.655 14.0933V10.5337H16.032V11.5251C16.042 12.7656 15.027 13.7795 13.762 13.7913H8.238C6.974 13.7795 5.959 12.7656 5.969 11.5251V10.5337H3.346V14.0972C3.346 15.424 4.447 16.5036 5.8 16.5056L7.428 16.5065C7.848 16.5065 8.189 16.8409 8.189 17.2528C8.188 17.6656 7.848 18 7.427 18L5.798 17.999C3.607 17.9971 1.823 16.2467 1.823 14.0972V10.5337H0.762C0.342 10.5337 0 10.2003 0 9.78845C0 9.37562 0.342 9.04123 0.762 9.04123Z"
            />
        </svg>
    );
};

export const Qr2D = memo(Qr2DInner);
