import React, { memo } from 'react';

const SubdivisionsInner = () => {
    return (
        <svg viewBox="0 0 24 24">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.61548 2C4.61548 0.89543 5.51091 0 6.61548 0H17.3847C18.4893 0 19.3847 0.89543 19.3847 2V7.23077C19.3847 8.33534 18.4893 9.23077 17.3847 9.23077H12.5V11.0769C12.5 11.353 12.2761 11.5769 12 11.5769C11.7239 11.5769 11.5 11.353 11.5 11.0769V9.23077H6.61548C5.51091 9.23077 4.61548 8.33534 4.61548 7.23077V2ZM3.5 15C3.5 14.1716 4.17157 13.5 5 13.5H11.5V18H9C8.44771 18 8 18.4477 8 19V23C8 23.5523 8.44771 24 9 24H15C15.5523 24 16 23.5523 16 23V19C16 18.4477 15.5523 18 15 18H12.5V13.5H19C19.8284 13.5 20.5 14.1716 20.5 15V16H19C18.4477 16 18 16.4477 18 17V20C18 20.5523 18.4477 21 19 21H23C23.5523 21 24 20.5523 24 20V17C24 16.4477 23.5523 16 23 16H21.5V15C21.5 13.6193 20.3807 12.5 19 12.5H5C3.61929 12.5 2.5 13.6193 2.5 15V16H1C0.447715 16 0 16.4477 0 17V20C0 20.5523 0.447715 21 1 21H5C5.55228 21 6 20.5523 6 20V17C6 16.4477 5.55228 16 5 16H3.5V15Z"
            />
        </svg>
    );
};

export const Subdivisions = memo(SubdivisionsInner);
