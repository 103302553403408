import React, { memo } from 'react';

const BarrierInner = () => {
    return (
        <svg viewBox="0 0 29 30">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.55592 0L0.200195 3.62622L6.18259 2.35573L2.55592 0ZM12.5794 12.0961L19.0583 10.7199L14.9496 8.05105L8.47062 9.42726L12.5794 12.0961ZM19.4662 12.6409L17.6716 15.4041L14.9084 13.6091L19.4662 12.6409ZM6.14111 7.9135L12.6205 6.53729L8.51175 3.86846L2.03236 5.24467L6.14111 7.9135ZM23.2267 12.1588H23.2258C20.4905 12.1588 18.2527 14.3966 18.2527 17.1323V29.9642H28.2002V17.1323C28.2002 14.3966 25.9619 12.1588 23.2267 12.1588ZM24.8532 22.2504C24.8532 21.345 24.119 20.6108 23.2136 20.6108C22.3082 20.6108 21.574 21.345 21.574 22.2504C21.574 23.1559 22.3082 23.89 23.2136 23.89C24.119 23.89 24.8532 23.1559 24.8532 22.2504Z"
            />
        </svg>
    );
};

export const Barrier = memo(BarrierInner);
