import {
    SetupWizardDevicesState,
    SetupWizardDevicesActions,
    SETUP_WIZAR_DEVICES_POINT_TYPES,
    SETUP_WIZAR_DEVICES_POINT_TYPE,
    SETUP_WIZAR_DEVICES_ACCESS_ZONES,
    SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_IN,
    SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_OUT,
    SETUP_WIZAR_DEVICES_CONTROLLER_TYPES,
    SETUP_WIZAR_DEVICES_CONTROLLER_TYPE,
    SETUP_WIZAR_DEVICES_CONNECTION_TYPES,
    SETUP_WIZAR_DEVICES_CONNECTION_TYPE,
    SETUP_WIZAR_DEVICES_POINT_NAME,
    SETUP_WIZAR_DEVICES_POINT_DESCRIPTION,
    SETUP_WIZAR_DEVICES_POINT_PROTOCOL,
    SETUP_WIZAR_DEVICES_POINT_MASK,
    SETUP_WIZAR_DEVICES_POINT_IP,
    SETUP_WIZAR_DEVICES_POINT_SERVER_PORT,
    SETUP_WIZAR_DEVICES_POINT_CLIENT_PORT,
    SETUP_WIZAR_DEVICES_POINT_PORT,
    SETUP_WIZAR_DEVICES_POINT_SPEED,
    SETUP_WIZAR_DEVICES_POINT_PARITY,
    SETUP_WIZAR_DEVICES_POINT_BIT,
    SETUP_WIZAR_DEVICES_POINT_STOP_BIT,
    SETUP_WIZAR_DEVICES_POINT_SERIAL_NUMBER,
    SETUP_WIZAR_DEVICES_POINT_BUS_PORT,
    SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_ADD,
    SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_REMOVE,
    SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_DIRECTION,
    SETUP_WIZAR_DEVICES_SEQUENCE_NOT_IMPORTANT,
    SETUP_WIZAR_DEVICES_USE_FOR_TIME_TRACKING,
    SETUP_WIZAR_DEVICES_ALLOW_OFFLINE_WORK,
    SETUP_WIZAR_DEVICES_CLEAR
} from './setupWizardDevicesTypes';

const initialState: SetupWizardDevicesState = {
        types: [],
        type: null,
        zonning: {
            accessZones: [],
            accessZoneOnIn: null,
            accessZoneOnOut: null
        },
        controllers: {
            controllerTypes: [],
            controllerType: null
        },
        connections: {
            connectionTypes: [],
            connectionType: null
        },
        accessPoint: {
            name: '',
            description: '',
            protocol: '',
            ip: '',
            mask: '',
            serverPort: '',
            clientPort: '',
            port: '',
            speed: '',
            parity: '',
            bit: '',
            stopBit: '',
            serialNumber: '',
            busPort: ''
        },
        accessModules: [],
        sequenceNotImportant: false,
        useForTimeTracking: false,
        allowOfflineWork: false
};

const SetupWizardDevices = (state = initialState, action: SetupWizardDevicesActions): SetupWizardDevicesState => {
    switch (action.type) {
        case SETUP_WIZAR_DEVICES_POINT_TYPES:
            return {
                ...state,
                types: action.payload
            };
        case SETUP_WIZAR_DEVICES_POINT_TYPE:
            return {
                ...state,
                type: action.payload
            };
        case SETUP_WIZAR_DEVICES_ACCESS_ZONES:
            return {
                ...state,
                zonning: {
                    ...state.zonning,
                    accessZones: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_IN:
            return {
                ...state,
                zonning: {
                    ...state.zonning,
                    accessZoneOnIn: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_OUT:
            return {
                ...state,
                zonning: {
                    ...state.zonning,
                    accessZoneOnOut: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_CONTROLLER_TYPES:
            return {
                ...state,
                controllers: {
                    ...state.controllers,
                    controllerTypes: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_CONTROLLER_TYPE:
            return {
                ...state,
                controllers: {
                    ...state.controllers,
                    controllerType: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_CONNECTION_TYPES:
            return {
                ...state,
                connections: {
                    ...state.connections,
                    connectionTypes: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_CONNECTION_TYPE:
            return {
                ...state,
                connections: {
                    ...state.connections,
                    connectionType: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_NAME:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    name: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_DESCRIPTION:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    description: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_PROTOCOL:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    protocol: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_IP:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    ip: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_MASK:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    mask: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_SERVER_PORT:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    serverPort: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_CLIENT_PORT:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    clientPort: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_PORT:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    port: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_SPEED:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    speed: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_PARITY:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    parity: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_BIT:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    bit: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_STOP_BIT:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    stopBit: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_SERIAL_NUMBER:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    serialNumber: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_BUS_PORT:
            return {
                ...state,
                accessPoint: {
                    ...state.accessPoint,
                    busPort: action.payload
                }
            };
        case SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_ADD:
            return {
                ...state,
                accessModules: [...state.accessModules, action.payload]
            };
        case SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_REMOVE:
            return {
                ...state,
                accessModules: action.payload
            };
        case SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_DIRECTION:
            return {
                ...state,
                accessModules: action.payload
            };
        case SETUP_WIZAR_DEVICES_SEQUENCE_NOT_IMPORTANT:
            return {
                ...state,
                sequenceNotImportant: !state.sequenceNotImportant
            };
        case SETUP_WIZAR_DEVICES_USE_FOR_TIME_TRACKING:
            return {
                ...state,
                useForTimeTracking: !state.useForTimeTracking
            };
        case SETUP_WIZAR_DEVICES_ALLOW_OFFLINE_WORK:
            return {
                ...state,
                allowOfflineWork: !state.allowOfflineWork
            };
        case SETUP_WIZAR_DEVICES_CLEAR:
            return {
                ...state,
                zonning: {
                    accessZones: [],
                    accessZoneOnIn: null,
                    accessZoneOnOut: null
                },
                controllers: {
                    controllerTypes: [],
                    controllerType: null
                },
                connections: {
                    connectionTypes: [],
                    connectionType: null
                },
                accessPoint: {
                    name: '',
                    description: '',
                    protocol: '',
                    ip: '',
                    mask: '',
                    serverPort: '',
                    clientPort: '',
                    port: '',
                    speed: '',
                    parity: '',
                    bit: '',
                    stopBit: '',
                    serialNumber: '',
                    busPort: ''
                }
            };
        default:
            return state;
    }
};

export default SetupWizardDevices;
