import React, { memo } from 'react';

const VisitorsInner = () => {
    return (
        <svg viewBox="0 0 24 18">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 4.5C0 2.01472 2.01472 0 4.5 0H6H12H18H19.5C21.9853 0 24 2.01472 24 4.5V9V13.5C24 15.9853 21.9853 18 19.5 18H18.72H12H6H4.5C2.01472 18 0 15.9853 0 13.5V9V4.5ZM8.57516 6.88097C8.57516 8.20264 7.52854 9.2624 6.22227 9.2624C4.91645 9.2624 3.86939 8.20264 3.86939 6.88097C3.86939 5.55931 4.91645 4.5 6.22227 4.5C7.52854 4.5 8.57516 5.55931 8.57516 6.88097ZM6.22229 13.5C4.29446 13.5 2.66674 13.1827 2.66674 11.9587C2.66674 10.7343 4.30469 10.4283 6.22229 10.4283C8.15057 10.4283 9.77785 10.7455 9.77785 11.9695C9.77785 13.194 8.1399 13.5 6.22229 13.5ZM12.4445 4.64996C12.0303 4.64996 11.6945 4.98575 11.6945 5.39996C11.6945 5.81418 12.0303 6.14996 12.4445 6.14996H19.5557C19.9699 6.14996 20.3057 5.81418 20.3057 5.39996C20.3057 4.98575 19.9699 4.64996 19.5557 4.64996H12.4445ZM11.6945 9C11.6945 8.58579 12.0303 8.25 12.4445 8.25H16.889C17.3032 8.25 17.639 8.58579 17.639 9C17.639 9.41421 17.3032 9.75 16.889 9.75H12.4445C12.0303 9.75 11.6945 9.41421 11.6945 9ZM12.4445 11.85C12.0303 11.85 11.6945 12.1858 11.6945 12.6C11.6945 13.0142 12.0303 13.35 12.4445 13.35H19.5557C19.9699 13.35 20.3057 13.0142 20.3057 12.6C20.3057 12.1858 19.9699 11.85 19.5557 11.85H12.4445Z"
            />
        </svg>
    );
};

export const Visitors = memo(VisitorsInner);
