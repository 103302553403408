import React, { memo } from 'react';

const ExitInner = () => {
    return (
        <svg viewBox="0 0 24 24">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66059 5.28491C6.28071 4.90503 5.6648 4.90503 5.28491 5.28491C4.90503 5.6648 4.90503 6.28071 5.28491 6.66059L10.6244 12.0001L5.29085 17.3336C4.91097 17.7135 4.91097 18.3294 5.29085 18.7093C5.67073 19.0892 6.28665 19.0892 6.66653 18.7093L12.0001 13.3758L17.3394 18.7151C17.7193 19.095 18.3352 19.095 18.7151 18.7151C19.095 18.3352 19.095 17.7193 18.7151 17.3394L13.3758 12.0001L18.7096 6.66628C19.0894 6.28639 19.0894 5.67048 18.7096 5.2906C18.3297 4.91071 17.7138 4.91071 17.3339 5.2906L12.0001 10.6244L6.66059 5.28491Z"
            />
        </svg>
    );
};

export const Exit = memo(ExitInner);
