import React, { memo } from 'react';

const ReloladInner = () => {
    return (
        <svg viewBox="0 0 32 32">
            <path d="M27.3159 4.26592C25.5186 2.47093 23.4049 1.19365 21.151 0.429535V5.2561C22.2361 5.7964 23.2647 6.49948 24.1667 7.40375C28.6723 11.9116 28.6723 19.2407 24.1667 23.7462C19.6612 28.245 12.3366 28.245 7.82423 23.7462C3.32321 19.2362 3.32321 11.9116 7.82423 7.40375C7.83554 7.39019 7.87171 7.36758 7.88753 7.34723H7.89205L10.5619 10.0126L10.5461 1.54857L2.08661 1.53727L4.74518 4.19584C4.72935 4.22296 4.70449 4.25009 4.68414 4.26592C-1.56213 10.5099 -1.56213 20.6401 4.68414 26.8841C10.9395 33.1349 21.0628 33.1349 27.3159 26.8841C33.5576 20.6401 33.5644 10.5099 27.3159 4.26592Z" />
        </svg>
    );
};

export const Relolad = memo(ReloladInner);
