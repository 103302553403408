import React, { memo } from 'react';

const FaceIdInner = () => {
    return (
        <svg viewBox="0 0 20 20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.10733 0C6.565 0 6.93658 0.371539 6.93658 0.830242C6.93658 1.28786 6.56609 1.65939 6.10733 1.65939L4.33224 1.66048C2.859 1.66266 1.66035 2.86226 1.66035 4.33643V6.40223C1.66035 6.85985 1.28768 7.23247 0.83001 7.23247C0.372344 7.23247 0.000762773 6.85985 0.000762773 6.40223V4.33643C0.000762773 1.94813 1.94366 0.00326867 4.33115 0.00108956L6.10733 0ZM13.9235 0.000326867H15.6594C18.0534 0.000326867 19.9996 1.94627 19.9996 4.34003V6.40256C19.9996 6.86017 19.6291 7.2328 19.1703 7.2328C18.7127 7.2328 18.3411 6.86017 18.3411 6.40256V4.34003C18.3411 2.8615 17.1381 1.65863 15.6594 1.65863H13.9235C13.4658 1.65863 13.0943 1.28818 13.0943 0.830569C13.0943 0.371865 13.4658 0.000326867 13.9235 0.000326867ZM20 15.6592V12.2222H18.3415V15.6592C18.3415 17.1388 17.1385 18.3406 15.6598 18.3406H13.9239C13.4652 18.3406 13.0936 18.7121 13.0936 19.1698C13.0936 19.6285 13.4652 20 13.9239 20H15.6598C18.0527 20 20 18.0541 20 15.6592ZM1.65958 12.2222H0V15.6636C0 18.0519 1.94399 19.9967 4.33148 19.9989L6.10657 20C6.56533 20 6.93582 19.6285 6.93691 19.1698C6.93691 18.7121 6.56533 18.3406 6.10766 18.3406L4.33366 18.3395C2.85932 18.3373 1.65958 17.1377 1.65958 15.6636V12.2222ZM9.79998 10.3499C11.5634 10.3499 12.9764 8.93685 12.9764 7.17463C12.9764 5.41241 11.5634 4 9.79998 4C8.03712 4 6.62359 5.41241 6.62359 7.17463C6.62359 8.93685 8.03712 10.3499 9.79998 10.3499ZM5 13.945C5 15.577 7.19742 16 9.8 16C12.3888 16 14.6 15.592 14.6 13.9594C14.6 12.3274 12.4032 11.9044 9.8 11.9044C7.21123 11.9044 5 12.3124 5 13.945Z"
            />
        </svg>
    );
};

export const FaceId = memo(FaceIdInner);
