import React, { memo } from 'react';

const ProfileInner = () => {
    return (
        <svg viewBox="0 0 22 22">
            <path d="M11 0C7.68786 0 5.00166 2.5696 5.00166 5.7387V6.6957C5.00166 9.8659 7.68786 12.4344 11.0022 12.4344C14.3165 12.4344 17.0027 9.8659 17.0027 6.6957V5.7387C17.0016 2.5696 14.3154 0 11 0ZM11 15.3043C6.99266 15.3043 1.85236 17.3767 0.373961 19.2159C-0.540139 20.3533 0.329961 22 1.82816 22H20.1718C21.67 22 22.5401 20.3533 21.626 19.217C20.1476 17.3778 15.0051 15.3043 11 15.3043Z" />
        </svg>
    );
};

export const Profile = memo(ProfileInner);
