import React from 'react';
import './CodesPage.scss';

export const CodesPage = () => {
    return (
        <section className="monitoring-params__content">
            {/* <div className="content__header">
                <h2>Коды</h2>
            </div> */}
            <div className="content__items">Наполнение страницы</div>
        </section>
    );
};
