import React, { memo } from 'react';

const StudentsInner = () => {
    return (
        <svg viewBox="0 0 22 16">
            <path d="M11 8.65164C11.6829 8.65164 12.3316 8.55735 12.8267 8.3861L19.511 6.07355V10.3567C18.9122 10.5667 18.4798 11.1241 18.4798 11.7841C18.4798 12.3884 18.8428 12.9071 19.3633 13.1522L18.5065 15.6792L19.4844 16L20.0267 14.4005L20.569 16L21.5468 15.6792L20.6901 13.1522C21.2105 12.9071 21.5735 12.3884 21.5735 11.7841C21.5735 11.1241 21.1412 10.5667 20.5423 10.3567V5.71677L20.8637 5.60558C21.8029 5.28052 22 4.73014 22 4.3257C22 3.9213 21.8028 3.37093 20.8636 3.04607L12.8267 0.26555C12.3316 0.0942977 11.6829 0 11 0C10.3172 0 9.66848 0.0942481 9.17331 0.26555L1.13648 3.04607C0.197141 3.37098 2.8048e-08 3.9213 2.8048e-08 4.3257C-8.58814e-05 4.73014 0.197141 5.28052 1.13639 5.60553L9.1734 8.38605C9.66848 8.5574 10.3172 8.65164 11 8.65164ZM11.0468 10.4101C10.2267 10.4101 9.46366 10.3129 8.84044 10.129L4.15991 8.74854V10.5458C4.15991 11.1414 4.55375 12.8457 6.43003 13.4606C7.67273 13.8679 9.31238 14.0922 11.0467 14.0922C12.7811 14.0922 14.4207 13.8679 15.6634 13.4606C17.5397 12.8457 17.9336 11.1415 17.9336 10.5458V8.74854L13.253 10.129C12.6298 10.3129 11.8668 10.4101 11.0468 10.4101Z" />
        </svg>
    );
};

export const Students = memo(StudentsInner);
