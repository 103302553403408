import React from 'react';
import './SourcesPage.scss';

export const SourcesPage = () => {
    return (
        <section className="monitoring-params__content">
            {/* <div className="content__header">
                <h2>Источники</h2>
            </div> */}
            <div className="content__items">Наполнение страницы</div>
        </section>
    );
};
