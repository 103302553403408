import React, { memo } from 'react';

const QuickIssueRfidInner = () => {
    return (
        <svg viewBox="0 0 22 18">
            <path d="M15.6035 0H4.62441C1.99192 0 0.268976 1.76068 0.0288634 4.32835C0.0941329 4.30868 0.163237 4.29812 0.23476 4.29812L19.9932 4.29812C20.0651 4.29812 20.1345 4.30879 20.2001 4.32867C19.961 1.76083 18.2439 0 15.6035 0ZM0 7.21662V5.73794C0.0736306 5.76361 0.1526 5.77755 0.23476 5.77755L19.9932 5.77755C20.0757 5.77755 20.155 5.7635 20.2288 5.73762V7.21695C20.155 7.19106 20.0757 7.17701 19.9932 7.17701L0.23476 7.17701C0.1526 7.17701 0.0736306 7.19095 0 7.21662ZM0 8.61683V12.7957C0 15.7005 1.77355 17.7532 4.62441 17.7532H12.7515C12.4796 17.068 12.3299 16.319 12.3299 15.5343C12.3299 12.266 14.9276 9.61655 18.132 9.61655C18.8714 9.61655 19.5785 9.75763 20.2288 10.0148V8.61651C20.155 8.6424 20.0757 8.65645 19.9932 8.65645L0.23476 8.65644C0.1526 8.65644 0.0736306 8.64251 0 8.61683ZM18.5404 16.953C18.2987 17.1909 18.2987 17.5765 18.5404 17.8144C18.7822 18.0605 19.174 18.0605 19.4157 17.8226L21.8166 15.4682C21.875 15.4108 21.9166 15.3452 21.95 15.2713C21.9833 15.1975 22 15.1237 22 15.0416C22 14.9596 21.9833 14.8776 21.95 14.8037C21.9166 14.7299 21.875 14.6643 21.8166 14.6069L19.4157 12.2607C19.174 12.0228 18.7822 12.0228 18.5404 12.2607C18.2987 12.4986 18.2987 12.8842 18.5404 13.1221L19.8742 14.4264H15.8478C15.506 14.4264 15.2309 14.6971 15.2309 15.0416C15.2309 15.378 15.506 15.6487 15.8478 15.6487H19.8742L18.5404 16.953Z" />
        </svg>
    );
};

export const QuickIssueRfid = memo(QuickIssueRfidInner);
