import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { State } from 'redux/store';
import { ArrowDrop } from 'assets/images/svgr/arrowDrop';
import { requestSideNavMenu } from 'redux/App/appActions';
import { SideNavIcon } from '../SideNavIcon';
import { SideNavMenu } from 'redux/App/appTypes';
import { requestSettingsParameters } from 'redux/Settings/SettingParameters/settingsParametersActions';
import SubLink from './SubLink/Sublink';

const SideNavLinkInner = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const sideNavState = useSelector((state: State) => state.app.sideNavMenu, shallowEqual);
    const { copyParam } = useSelector((state: State) => state.settings.settingsParameters, shallowEqual);
    const [filteredSideNav, setFilteredSideNav] = useState<SideNavMenu>([]);

    useEffect(() => {
        dispatch(requestSideNavMenu());
    }, [dispatch, copyParam]);

    const addActiveMenuShadow = () => {
        const activePage = document.querySelector('.active-page');

        if (activePage) {
            const navLink = activePage.closest('.nav-link');

            if (navLink) {
                navLink.classList.add('nav--active');
            }
        }
    };

    const removeSctiveMenuShadow = () => {
        const navActiveArr = document.querySelector('.nav--active');

        if (navActiveArr) {
            navActiveArr.classList.remove('nav--active');
        }
    };

    const spreadSubmenuOnOpenPage = () => {
        const activePage = document.querySelector('.active-page');

        if (activePage) {
            const navLink = activePage.closest('.nav-link');

            if (navLink) {
                navLink.classList.add('show');
                navLink.querySelector('.list-name__icon')!.classList.add('turn');
            }
        }
    };

    const spreadSubmenuOnActive = (e: React.SyntheticEvent) => {
        const clickedMenuLinck = e.currentTarget.parentElement;

        clickedMenuLinck?.classList.toggle('show');
        clickedMenuLinck?.querySelector('.list-name__icon')?.classList.toggle('turn');
    };

    useEffect(() => {
        addActiveMenuShadow();
        spreadSubmenuOnOpenPage();
    });

    useEffect(() => {
        return () => {
            removeSctiveMenuShadow();
            addActiveMenuShadow();
        };
    }, [location]);

    const findIndexFunc = (arr: any[], index: any) => {
        return arr.findIndex((elem) => {
            const searchingElem = arr.find((elem) => elem.name === index);

            return elem === searchingElem;
        });
    };

    useEffect(() => {
        dispatch(requestSettingsParameters());
    }, [dispatch]);

    // FILTER SIDENAVSTATE
    useEffect(() => {
        if (sideNavState.length > 0 && copyParam.length > 0) {
            const identifiersOptions = copyParam[findIndexFunc(copyParam, 'identifiers')].options;
            const identifiersUseType = identifiersOptions[findIndexFunc(identifiersOptions, 'useType')];

            const filter = sideNavState.map((navItem) => {
                if (navItem.linkName === 'Идентификаторы') {
                    const sublinks = navItem.linkSubLink!.filter((navSubItem) => {
                        if (navSubItem.sublinkName === 'Бесконтактные RFID ключи') {
                            return identifiersUseType.selectedList![
                                identifiersUseType.list!.findIndex((itemArr) => itemArr.includes('RFID ключ'))
                            ];
                        } else if (navSubItem.sublinkName === 'Face ID') {
                            return identifiersUseType.selectedList![
                                identifiersUseType.list!.findIndex((itemArr) => itemArr.includes('Биометрия - фотография'))
                            ];
                        } else if (navSubItem.sublinkName === '2D штрих-коды') {
                            return identifiersUseType.selectedList![
                                identifiersUseType.list!.findIndex((itemArr) => itemArr.includes('2D-штрих'))
                            ];
                        } else {
                            return false;
                        }
                    });

                    navItem.linkSubLink = [...sublinks];

                    return navItem;
                } else {
                    return navItem;
                }
            });

            setFilteredSideNav([...filter]);
        }
    }, [sideNavState, copyParam]);

    return (
        <ul className="side__top">
            {filteredSideNav.map((elem, id) => {
                return (
                    <div key={elem.linkName} className="nav-wrapper">
                        {elem.linkSubLink ? (
                            <div className="nav-link">
                                <button type="button" className="font" onClick={spreadSubmenuOnActive}>
                                    <SideNavIcon linkName={elem.linkName} />

                                    <div className="list-name">
                                        <div className="list-name__title">
                                            <div className="p--md--normal">{elem.linkName}</div>
                                        </div>

                                        <div className="list-name__icon">
                                            <ArrowDrop />
                                        </div>
                                    </div>
                                </button>

                                <SubLink id={id} />
                            </div>
                        ) : (
                            <NavLink activeClassName="active-page" to={elem.linkUrl!} className="nav-link" tabIndex={-1}>
                                <button type="button" className="font">
                                    <SideNavIcon linkName={elem.linkName} />

                                    <div className="list-name">
                                        <div className="list-name__title">
                                            <div className="p--md--normal">{elem.linkName}</div>
                                        </div>
                                    </div>
                                </button>
                            </NavLink>
                        )}
                    </div>
                );
            })}
        </ul>
    );
};

export const SideNavLink = memo(SideNavLinkInner);
