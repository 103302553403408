import React, { memo } from 'react';

const ExitEventInner = () => {
    return (
        <svg viewBox="0 0 17 16">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.31628 7.384C5.96628 7.384 5.68953 7.656 5.68953 8C5.68953 8.336 5.96628 8.616 6.31628 8.616H11.2V12.44C11.2 14.4 9.58023 16 7.57791 16H3.61395C1.61977 16 0 14.408 0 12.448V3.56C0 1.592 1.62791 0 3.62209 0H7.59419C9.58023 0 11.2 1.592 11.2 3.552V7.384H6.31628ZM14.1042 5.23216L16.4402 7.56016C16.5602 7.68016 16.6242 7.83216 16.6242 8.00016C16.6242 8.16016 16.5602 8.32016 16.4402 8.43216L14.1042 10.7602C13.9842 10.8802 13.8242 10.9442 13.6722 10.9442C13.5122 10.9442 13.3522 10.8802 13.2322 10.7602C12.9922 10.5202 12.9922 10.1282 13.2322 9.88816L14.5122 8.61616H11.2002V7.38416H14.5122L13.2322 6.11216C12.9922 5.87216 12.9922 5.48016 13.2322 5.24016C13.4722 4.99216 13.8642 4.99216 14.1042 5.23216Z"
            />
        </svg>
    );
};

export const ExitEvent = memo(ExitEventInner);
