import React, { memo } from 'react';

const CalendarInner = () => {
    return (
        <svg viewBox="0 0 18 20">
            <path d="M18 6.5C18 2.2 16.7 1.6 13.4 1.5V0.8C13.4 0.4 13.1 0 12.6 0C12.2 0 11.8 0.3 11.8 0.8V1.5H6V0.8C6 0.3 5.7 0 5.3 0C4.8 0 4.5 0.3 4.5 0.8V1.5C0.7 1.6 0 3 0 6.6V14.9C0 18 1.3 20 5.2 20H12.9C16.9 20 18 18.1 18 14.9V6.5ZM5 15.9C4.5 15.9 4.2 15.6 4.1 15.1C4.1 14.6 4.5 14.3 4.9 14.2C5.3 14.2 5.7 14.5 5.7 15C5.8 15.5 5.4 15.8 5 15.9ZM4.1 11.5C4.1 11 4.5 10.7 4.9 10.6C5.3 10.6 5.7 10.9 5.7 11.4C5.7 11.9 5.4 12.2 4.9 12.3C4.5 12.3 4.1 11.9 4.1 11.5ZM9 15.8C8.5 15.8 8.2 15.5 8.1 15C8.1 14.5 8.5 14.2 8.9 14.1C9.3 14.1 9.7 14.4 9.7 14.9C9.8 15.4 9.5 15.8 9 15.8ZM8.2 11.4C8.2 10.9 8.6 10.6 9 10.5C9.4 10.5 9.8 10.8 9.8 11.3C9.8 11.8 9.5 12.1 9 12.2C8.6 12.2 8.2 11.9 8.2 11.4ZM13.1 15.9C12.6 15.9 12.3 15.5 12.3 15.1C12.3 14.6 12.7 14.3 13.1 14.2C13.6 14.2 13.9 14.6 13.9 15C13.9 15.5 13.5 15.9 13.1 15.9ZM12.2 11.4C12.2 10.9 12.6 10.6 13 10.5C13.4 10.5 13.8 10.9 13.8 11.3C13.8 11.8 13.5 12.1 13 12.2C12.6 12.2 12.2 11.9 12.2 11.4ZM16.5 6.8H1.6V6.5C1.3 3.3 2.5 3.1 4.6 3V3.8C4.6 4.2 4.9 4.6 5.4 4.6C5.7 4.6 6 4.3 6 3.8V3H11.9V3.8C11.9 4.2 12.2 4.6 12.7 4.6C13.1 4.6 13.5 4.3 13.5 3.8V3C16.4 3 16.6 3.7 16.6 6.4V6.8H16.5Z" />
        </svg>
    );
};

export const Calendar = memo(CalendarInner);
