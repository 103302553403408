import React, { memo } from 'react';

const TurniketInner = () => {
    return (
        <svg viewBox="0 0 30 28">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 28C0.671573 28 0 27.3284 0 26.5V2.80882C0.182413 1.19736 0.896505 0.1206 2.55595 0H12.6345C15.3028 0.247684 15.4831 1.56348 15.2112 3.07898C13.5936 4.73104 11.9762 6.3833 10.3587 8.03561C9.54101 8.8709 8.72332 9.7062 7.90558 10.5415V26.5C7.90558 27.3284 7.23401 28 6.40558 28H1.5ZM19.9617 9.4786C19.803 8.40314 19.3102 7.36745 18.482 6.53967L17.7381 7.28359C17.5267 7.49539 17.2782 7.74394 17.0033 8.01843L11.0688 13.9529C12.1192 15.0037 13.505 15.5151 14.8822 15.487L14.8813 23.4574C14.8813 23.9394 15.2725 24.3306 15.7549 24.3306C16.2378 24.3306 16.6289 23.9394 16.6289 23.4574V15.1511C17.3046 14.8961 17.9383 14.4967 18.482 13.9529C19.2558 13.1796 19.7369 12.2252 19.9258 11.2258H28.861C29.3434 11.2258 29.7346 10.8346 29.7346 10.3522C29.7346 9.86979 29.3434 9.4786 28.861 9.4786H19.9617ZM16.5615 4.4886L16.4794 4.41598C16.1366 4.14971 15.6412 4.17392 15.327 4.48947L9.01946 10.8017C8.67884 11.1432 8.67884 11.6961 9.01946 12.0376L9.04151 12.06C9.38299 12.4007 9.93585 12.4015 10.276 12.06L16.584 5.74734C16.9242 5.40586 16.9242 4.853 16.584 4.51194L16.5615 4.4886Z"
            />
        </svg>
    );
};

export const Turniket = memo(TurniketInner);
