import React, { memo } from 'react';

const MoonInner = () => {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M12,11.807C9.349,9.155,8.7,5.261,10.049,2c-1.875,0.37-3.666,1.281-5.12,2.735c-3.905,3.905-3.905,10.237,0,14.142	c3.906,3.906,10.237,3.905,14.143,0c1.454-1.454,2.364-3.244,2.735-5.119C18.545,15.106,14.651,14.458,12,11.807z" />
        </svg>
    );
};

export const Moon = memo(MoonInner);
