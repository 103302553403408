import React, { memo } from 'react';

const FilterInner = () => {
    return (
        <svg viewBox="0 0 20 18">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 8.17071L4 1C4 0.447715 3.55228 0 3 0C2.44771 0 2 0.447715 2 1L2 8.17071C0.834807 8.58254 0 9.69378 0 11C0 12.3062 0.834807 13.4175 2 13.8293L2 17C2 17.5523 2.44772 18 3 18C3.55228 18 4 17.5523 4 17L4 13.8293C5.16519 13.4175 6 12.3062 6 11C6 9.69378 5.16519 8.58254 4 8.17071ZM3 10C2.44772 10 2 10.4477 2 11C2 11.5523 2.44772 12 3 12C3.55228 12 4 11.5523 4 11C4 10.4477 3.55228 10 3 10Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 18C16.4477 18 16 17.5523 16 17L16 15C16 14.9435 16.0047 14.8881 16.0137 14.8341C14.8414 14.4262 14 13.3113 14 12C14 10.6887 14.8414 9.57385 16.0137 9.1659C16.0047 9.11194 16 9.05652 16 9L16 1C16 0.447715 16.4477 0 17 0C17.5523 0 18 0.447715 18 1L18 9C18 9.05652 17.9953 9.11194 17.9863 9.1659C19.1586 9.57385 20 10.6887 20 12C20 13.3113 19.1586 14.4262 17.9863 14.8341C17.9953 14.8881 18 14.9435 18 15V17C18 17.5523 17.5523 18 17 18ZM16 12C16 11.4477 16.4477 11 17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13C16.4477 13 16 12.5523 16 12Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 6C7 4.69378 7.83481 3.58254 9 3.17071V1C9 0.447715 9.44772 0 10 0C10.5523 0 11 0.447715 11 1V3.17071C12.1652 3.58254 13 4.69378 13 6C13 7.31133 12.1586 8.42615 10.9863 8.8341C10.9953 8.88806 11 8.94348 11 9L11 17C11 17.5523 10.5523 18 10 18C9.44771 18 9 17.5523 9 17L9 9C9 8.94348 9.00469 8.88806 9.0137 8.8341C7.84135 8.42615 7 7.31133 7 6ZM9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6C11 6.55229 10.5523 7 10 7C9.44772 7 9 6.55229 9 6Z"
            />
        </svg>
    );
};

export const Filter = memo(FilterInner);
