import React, { memo } from 'react';

const ImportPdfInner = () => {
    return (
        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.08934 5.42241C2.84181 5.17241 2.44065 5.17241 2.19313 5.42241C1.93707 5.67241 1.93707 6.07759 2.18459 6.32759L4.63423 8.81034C4.69398 8.87069 4.76226 8.91379 4.83908 8.94828C4.9159 8.98276 4.99272 9 5.07807 9C5.16342 9 5.24878 8.98276 5.32559 8.94828C5.40241 8.91379 5.4707 8.87069 5.53044 8.81034L7.97155 6.32759C8.21907 6.07759 8.21907 5.67241 7.97155 5.42241C7.72402 5.17241 7.32286 5.17241 7.07534 5.42241L5.71822 6.80172L5.71822 2.63793C5.71822 2.28448 5.43655 2 5.07807 2C4.72812 2 4.44646 2.28448 4.44646 2.63793L4.44646 6.80172L3.08934 5.42241ZM15.8282 7.02561C16.0609 7.02292 16.3143 7.02 16.5446 7.02C16.7921 7.02 17 7.22 17 7.47V15.51C17 17.99 15 20 12.5446 20H4.67327C2.08911 20 0 17.89 0 15.29V4.51C0 2.03 1.9901 0 4.46535 0H9.75247C10 0 10.2079 0.21 10.2079 0.46V3.68C10.2079 5.51 11.6931 7.01 13.5149 7.02C13.9313 7.02 14.301 7.02315 14.6258 7.02591C14.8801 7.02807 15.1069 7.03 15.3069 7.03C15.4479 7.03 15.6306 7.02789 15.8282 7.02561ZM16.1047 5.566C15.2908 5.569 14.3324 5.566 13.6423 5.559C12.5472 5.559 11.6452 4.648 11.6452 3.542V0.906C11.6452 0.475 12.1621 0.261 12.4581 0.572C13.2208 1.37218 14.3884 2.59857 15.375 3.63496C15.7744 4.05437 16.144 4.44267 16.4452 4.759C16.7334 5.062 16.5215 5.565 16.1047 5.566Z"
                fill="#293440"
            />
            <path
                d="M3.46973 15.2466V17H2.44434V12.0234H4.38574C4.75944 12.0234 5.08757 12.0918 5.37012 12.2285C5.65495 12.3652 5.8737 12.5601 6.02637 12.813C6.17904 13.0636 6.25537 13.3496 6.25537 13.6709C6.25537 14.1585 6.08789 14.5436 5.75293 14.8262C5.42025 15.1064 4.95882 15.2466 4.36865 15.2466H3.46973ZM3.46973 14.416H4.38574C4.6569 14.416 4.86312 14.3522 5.00439 14.2246C5.14795 14.097 5.21973 13.9147 5.21973 13.6777C5.21973 13.4339 5.14795 13.2368 5.00439 13.0864C4.86084 12.936 4.6626 12.8586 4.40967 12.854H3.46973V14.416ZM6.96289 17V12.0234H8.49414C8.93164 12.0234 9.32243 12.1226 9.6665 12.3208C10.0129 12.5168 10.2829 12.797 10.4766 13.1616C10.6702 13.5239 10.7671 13.9364 10.7671 14.3989V14.6279C10.7671 15.0905 10.6714 15.5018 10.48 15.8618C10.2909 16.2218 10.0231 16.501 9.67676 16.6992C9.3304 16.8975 8.93962 16.9977 8.50439 17H6.96289ZM7.98828 12.854V16.1763H8.48389C8.88493 16.1763 9.19141 16.0452 9.40332 15.7832C9.61523 15.5212 9.72347 15.1463 9.72803 14.6587V14.3955C9.72803 13.8896 9.62321 13.5068 9.41357 13.2471C9.20394 12.985 8.89746 12.854 8.49414 12.854H7.98828ZM14.5098 14.9663H12.541V17H11.5156V12.0234H14.7559V12.854H12.541V14.1392H14.5098V14.9663Z"
                fill="white"
            />
        </svg>
    );
};

export const ImportPdf = memo(ImportPdfInner);
