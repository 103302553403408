import React, { memo } from 'react';

const ParametersInner = () => {
    return (
        <svg viewBox="0 0 18 20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.17071 16L1 16C0.447714 16 -1.55275e-07 16.4477 -1.31134e-07 17C-1.06993e-07 17.5523 0.447715 18 1 18L8.17071 18C8.58254 19.1652 9.69378 20 11 20C12.3062 20 13.4175 19.1652 13.8293 18L17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16L13.8293 16C13.4175 14.8348 12.3062 14 11 14C9.69378 14 8.58254 14.8348 8.17071 16ZM10 17C10 17.5523 10.4477 18 11 18C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16C10.4477 16 10 16.4477 10 17Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 3C18 3.55228 17.5523 4 17 4L15 4C14.9435 4 14.8881 3.99531 14.8341 3.9863C14.4262 5.15864 13.3113 6 12 6C10.6887 6 9.57385 5.15865 9.1659 3.9863C9.11194 3.99531 9.05652 4 9 4L0.999999 4C0.447714 4 -7.18952e-07 3.55229 -7.43094e-07 3C-7.67235e-07 2.44772 0.447714 2 0.999999 2L9 2C9.05652 2 9.11194 2.00469 9.1659 2.0137C9.57385 0.841354 10.6887 -4.67216e-07 12 -5.24537e-07C13.3113 -5.81857e-07 14.4262 0.841354 14.8341 2.0137C14.8881 2.00469 14.9435 2 15 2L17 2C17.5523 2 18 2.44771 18 3ZM12 4C11.4477 4 11 3.55228 11 3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3C13 3.55228 12.5523 4 12 4Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 13C4.69378 13 3.58254 12.1652 3.17071 11L1 11C0.447715 11 -4.12973e-07 10.5523 -4.37114e-07 10C-4.61255e-07 9.44771 0.447715 9 1 9L3.17071 9C3.58254 7.83481 4.69378 7 6 7C7.31133 7 8.42615 7.84135 8.8341 9.0137C8.88806 9.00469 8.94348 9 9 9L17 9C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11L9 11C8.94348 11 8.88806 10.9953 8.83409 10.9863C8.42615 12.1586 7.31133 13 6 13ZM6 11C5.44772 11 5 10.5523 5 10C5 9.44772 5.44772 9 6 9C6.55228 9 7 9.44772 7 10C7 10.5523 6.55228 11 6 11Z"
            />
        </svg>
    );
};

export const Parameters = memo(ParametersInner);
