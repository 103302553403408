export interface AccesPoint {
    name: string;
    description: string;
    protocol: string;
    ip: string;
    mask: string;
    serverPort: string;
    clientPort: string;
    port: string;
    speed: string;
    parity: string;
    bit: string;
    stopBit: string;
    serialNumber: string;
    busPort: string;
}

export interface AccessModule {
    index: number;
    class: string;
    type: string;
    name: string;
    direction: string;
    sequence: ModuleSecuence;
}

export interface ModuleSecuence {
    onEnter: boolean;
    onExit: boolean;
}

export type DeviceTypes = Array<DeviceType>;

export interface DeviceType {
    id: number;
    name: string;
    enabled: boolean;
    idx: number;
}

export type AccessZones = Array<AccessZone>;

export interface AccessZone {
    uuid: string;
    id: number;
    name: string;
    description: string;
    parentUUID: string;
    enabled: number;
}

export interface Zonning {
    accessZones: AccessZones;
    accessZoneOnIn: null | AccessZone;
    accessZoneOnOut: null | AccessZone;
}

export type ControllerTypes = Array<ControllerType>;

export interface ControllerType {
    id: number;
    name: string;
    deviceTypeId: number;
    deviceTypeName: string;
    syncSupport: number;
    syncSupportStr: string;
}

export interface Controllers {
    controllerTypes: ControllerTypes;
    controllerType: null | ControllerType;
}

export type ConnectionTypes = Array<ConnectionType>

export interface ConnectionType {
    id: number;
    name: string;
}

export interface Connections {
    connectionTypes: ConnectionTypes;
    connectionType: null | ConnectionType;
}

export interface SetupWizardDevicesState {
    types: DeviceTypes;
    type: null | DeviceType;
    zonning: Zonning;
    controllers: Controllers;
    connections: Connections;
    accessPoint: AccesPoint;
    accessModules: AccessModule[];
    sequenceNotImportant: boolean;
    useForTimeTracking:boolean;
    allowOfflineWork: boolean;
}

export const SETUP_WIZAR_DEVICES_POINT_TYPES = 'SETUP_WIZAR_DEVICES_POINT_TYPES';
export const SETUP_WIZAR_DEVICES_POINT_TYPE = 'SETUP_WIZAR_DEVICES_POINT_TYPE';
export const SETUP_WIZAR_DEVICES_ACCESS_ZONES = 'SETUP_WIZAR_DEVICES_ACCESS_ZONES';
export const SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_IN = 'SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_IN';
export const SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_OUT = 'SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_OUT';
export const SETUP_WIZAR_DEVICES_CONTROLLER_TYPES = 'SETUP_WIZAR_DEVICES_CONTROLLER_TYPES';
export const SETUP_WIZAR_DEVICES_CONTROLLER_TYPE = 'SETUP_WIZAR_DEVICES_CONTROLLER_TYPE';
export const SETUP_WIZAR_DEVICES_CONNECTION_TYPES = 'SETUP_WIZAR_DEVICES_CONNECTION_TYPES';
export const SETUP_WIZAR_DEVICES_CONNECTION_TYPE = 'SETUP_WIZAR_DEVICES_CONNECTION_TYPE';
export const SETUP_WIZAR_DEVICES_POINT_NAME = 'SETUP_WIZAR_DEVICES_POINT_NAME';
export const SETUP_WIZAR_DEVICES_POINT_DESCRIPTION = 'SETUP_WIZAR_DEVICES_POINT_DESCRIPTION';

export const SETUP_WIZAR_DEVICES_POINT_PROTOCOL = 'SETUP_WIZAR_DEVICES_POINT_PROTOCOL';
export const SETUP_WIZAR_DEVICES_POINT_IP = 'SETUP_WIZAR_DEVICES_POINT_IP';
export const SETUP_WIZAR_DEVICES_POINT_MASK = 'SETUP_WIZAR_DEVICES_POINT_MASK';
export const SETUP_WIZAR_DEVICES_POINT_SERVER_PORT = 'SETUP_WIZAR_DEVICES_POINT_SERVER_PORT';
export const SETUP_WIZAR_DEVICES_POINT_CLIENT_PORT = 'SETUP_WIZAR_DEVICES_POINT_CLIENT_PORT';

export const SETUP_WIZAR_DEVICES_POINT_PORT = 'SETUP_WIZAR_DEVICES_POINT_PORT';
export const SETUP_WIZAR_DEVICES_POINT_SPEED = 'SETUP_WIZAR_DEVICES_POINT_SPEED';
export const SETUP_WIZAR_DEVICES_POINT_PARITY = 'SETUP_WIZAR_DEVICES_POINT_PARITY';
export const SETUP_WIZAR_DEVICES_POINT_BIT = 'SETUP_WIZAR_DEVICES_POINT_BIT';
export const SETUP_WIZAR_DEVICES_POINT_STOP_BIT = 'SETUP_WIZAR_DEVICES_POINT_STOP_BIT';

export const SETUP_WIZAR_DEVICES_POINT_SERIAL_NUMBER = 'SETUP_WIZAR_DEVICES_POINT_SERIAL_NUMBER';
export const SETUP_WIZAR_DEVICES_POINT_BUS_PORT = 'SETUP_WIZAR_DEVICES_POINT_BUS_PORT';
export const SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_ADD = 'SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_ADD';
export const SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_REMOVE = 'SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_REMOVE';
export const SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_DIRECTION =
    'SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_DIRECTION';
export const SETUP_WIZAR_DEVICES_SEQUENCE_NOT_IMPORTANT = 'SETUP_WIZAR_DEVICES_SEQUENCE_NOT_IMPORTANT';
export const SETUP_WIZAR_DEVICES_USE_FOR_TIME_TRACKING = 'SETUP_WIZAR_DEVICES_USE_FOR_TIME_TRACKING';
export const SETUP_WIZAR_DEVICES_ALLOW_OFFLINE_WORK = 'SETUP_WIZAR_DEVICES_ALLOW_OFFLINE_WORK';
export const SETUP_WIZAR_DEVICES_CLEAR = 'SETUP_WIZAR_DEVICES_CLEAR';

interface SetupWizardDevicesPointTypes {
    type: typeof SETUP_WIZAR_DEVICES_POINT_TYPES;
    payload: DeviceTypes;
}

interface SetupWizardDevicesPointType {
    type: typeof SETUP_WIZAR_DEVICES_POINT_TYPE;
    payload: DeviceType;
}

interface SetupWizardDevicesAccessZones {
    type: typeof SETUP_WIZAR_DEVICES_ACCESS_ZONES;
    payload: AccessZones;
}

interface SetupWizardDevicesAccessZoneOnIn {
    type: typeof SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_IN;
    payload: AccessZone;
}

interface SetupWizardDevicesAccessZoneOnOut {
    type: typeof SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_OUT;
    payload: AccessZone;
}

interface SetupWizardDevicesControllerTypes {
    type: typeof SETUP_WIZAR_DEVICES_CONTROLLER_TYPES;
    payload: ControllerTypes;
}

interface SetupWizardDevicesControllerType {
    type: typeof SETUP_WIZAR_DEVICES_CONTROLLER_TYPE;
    payload: ControllerType;
}

interface SetupWizardDevicesConnectionTypes {
    type: typeof SETUP_WIZAR_DEVICES_CONNECTION_TYPES;
    payload: ConnectionTypes;
}

interface SetupWizardDevicesConnectionType {
    type: typeof SETUP_WIZAR_DEVICES_CONNECTION_TYPE;
    payload: ConnectionType;
}

interface SetupWizardDevicesPointName {
    type: typeof SETUP_WIZAR_DEVICES_POINT_NAME;
    payload: string;
}

interface SetupWizardDevicesPointDescription {
    type: typeof SETUP_WIZAR_DEVICES_POINT_DESCRIPTION;
    payload: string;
}

interface SetupWizardDevicesPointProtocol {
    type: typeof SETUP_WIZAR_DEVICES_POINT_PROTOCOL;
    payload: string;
}

interface SetupWizardDevicesPointIp {
    type: typeof SETUP_WIZAR_DEVICES_POINT_IP;
    payload: string;
}

interface SetupWizardDevicesPointMask {
    type: typeof SETUP_WIZAR_DEVICES_POINT_MASK;
    payload: string;
}

interface SetupWizardDevicesPointServerPort {
    type: typeof SETUP_WIZAR_DEVICES_POINT_SERVER_PORT;
    payload: string;
}

interface SetupWizardDevicesPointClientPort {
    type: typeof SETUP_WIZAR_DEVICES_POINT_CLIENT_PORT;
    payload: string;
}

interface SetupWizardDevicesPointPort {
    type: typeof SETUP_WIZAR_DEVICES_POINT_PORT;
    payload: string;
}

interface SetupWizardDevicesPointSpeed {
    type: typeof SETUP_WIZAR_DEVICES_POINT_SPEED;
    payload: string;
}

interface SetupWizardDevicesPointParity {
    type: typeof SETUP_WIZAR_DEVICES_POINT_PARITY;
    payload: string;
}

interface SetupWizardDevicesPointBit {
    type: typeof SETUP_WIZAR_DEVICES_POINT_BIT;
    payload: string;
}

interface SetupWizardDevicesPointStopBit {
    type: typeof SETUP_WIZAR_DEVICES_POINT_STOP_BIT;
    payload: string;
}

interface SetupWizardDevicesPointSerialNumber {
    type: typeof SETUP_WIZAR_DEVICES_POINT_SERIAL_NUMBER;
    payload: string;
}

interface SetupWizardDevicesPointBusPort {
    type: typeof SETUP_WIZAR_DEVICES_POINT_BUS_PORT;
    payload: string;
}

interface SetupWizardDevicesPointAccessModuleAdd {
    type: typeof SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_ADD;
    payload: AccessModule;
}

interface SetupWizardDevicesPointAccessModuleRemove {
    type: typeof SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_REMOVE;
    payload: AccessModule[];
}

interface SetupWizardDevicesPointAccessModuleDirection {
    type: typeof SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_DIRECTION;
    payload: AccessModule[];
}

interface SetupWizardDevicesSequenceNotImportant {
    type: typeof SETUP_WIZAR_DEVICES_SEQUENCE_NOT_IMPORTANT;
}

interface SetupWizardDevicesUseForTimeTracking {
    type: typeof SETUP_WIZAR_DEVICES_USE_FOR_TIME_TRACKING;
}

interface SetupWizardDevicesAllowOfflineWork {
    type: typeof SETUP_WIZAR_DEVICES_ALLOW_OFFLINE_WORK;
}

interface SetupWizardDevicesClear {
    type: typeof SETUP_WIZAR_DEVICES_CLEAR;
}

export type SetupWizardDevicesActions =
    | SetupWizardDevicesPointTypes
    | SetupWizardDevicesPointType
    | SetupWizardDevicesAccessZones
    | SetupWizardDevicesAccessZoneOnIn
    | SetupWizardDevicesAccessZoneOnOut
    | SetupWizardDevicesControllerTypes
    | SetupWizardDevicesControllerType
    | SetupWizardDevicesConnectionTypes
    | SetupWizardDevicesConnectionType
    | SetupWizardDevicesPointName
    | SetupWizardDevicesPointDescription
    | SetupWizardDevicesPointProtocol
    | SetupWizardDevicesPointIp
    | SetupWizardDevicesPointMask
    | SetupWizardDevicesPointServerPort
    | SetupWizardDevicesPointClientPort
    | SetupWizardDevicesPointPort
    | SetupWizardDevicesPointSpeed
    | SetupWizardDevicesPointParity
    | SetupWizardDevicesPointBit
    | SetupWizardDevicesPointStopBit
    | SetupWizardDevicesPointSerialNumber
    | SetupWizardDevicesPointBusPort
    | SetupWizardDevicesPointAccessModuleAdd
    | SetupWizardDevicesPointAccessModuleRemove
    | SetupWizardDevicesPointAccessModuleDirection
    | SetupWizardDevicesSequenceNotImportant
    | SetupWizardDevicesUseForTimeTracking
    | SetupWizardDevicesAllowOfflineWork
    | SetupWizardDevicesClear;
