import React, { memo } from 'react';

const ImportXlsInner = () => {
    return (
        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.08934 5.42241C2.84181 5.17241 2.44065 5.17241 2.19313 5.42241C1.93707 5.67241 1.93707 6.07759 2.18459 6.32759L4.63423 8.81034C4.69398 8.87069 4.76226 8.91379 4.83908 8.94828C4.9159 8.98276 4.99272 9 5.07807 9C5.16342 9 5.24878 8.98276 5.32559 8.94828C5.40241 8.91379 5.4707 8.87069 5.53044 8.81034L7.97155 6.32759C8.21907 6.07759 8.21907 5.67241 7.97155 5.42241C7.72402 5.17241 7.32286 5.17241 7.07534 5.42241L5.71822 6.80172L5.71822 2.63793C5.71822 2.28448 5.43655 2 5.07807 2C4.72812 2 4.44646 2.28448 4.44646 2.63793L4.44646 6.80172L3.08934 5.42241ZM15.8282 7.02561C16.0609 7.02292 16.3143 7.02 16.5446 7.02C16.7921 7.02 17 7.22 17 7.47V15.51C17 17.99 15 20 12.5446 20H4.67327C2.08911 20 0 17.89 0 15.29V4.51C0 2.03 1.9901 0 4.46535 0H9.75247C10 0 10.2079 0.21 10.2079 0.46V3.68C10.2079 5.51 11.6931 7.01 13.5149 7.02C13.9313 7.02 14.301 7.02315 14.6258 7.02591C14.8801 7.02807 15.1069 7.03 15.3069 7.03C15.4479 7.03 15.6306 7.02789 15.8282 7.02561ZM16.1047 5.566C15.2908 5.569 14.3324 5.566 13.6423 5.559C12.5472 5.559 11.6452 4.648 11.6452 3.542V0.906C11.6452 0.475 12.1621 0.261 12.4581 0.572C13.2208 1.37218 14.3884 2.59857 15.375 3.63496C15.7744 4.05437 16.144 4.44267 16.4452 4.759C16.7334 5.062 16.5215 5.565 16.1047 5.566Z"
                fill="#293440"
            />
            <path
                d="M4.2251 13.7393L5.1582 12.0234H6.3374L4.88818 14.4912L6.375 17H5.18213L4.2251 15.2568L3.26807 17H2.0752L3.56201 14.4912L2.11279 12.0234H3.29199L4.2251 13.7393ZM7.91992 16.1763H10.0972V17H6.89453V12.0234H7.91992V16.1763ZM13.269 15.6943C13.269 15.5007 13.2007 15.3525 13.064 15.25C12.9272 15.1452 12.6812 15.0358 12.3257 14.9219C11.9702 14.8057 11.6888 14.6917 11.4814 14.5801C10.9163 14.2747 10.6338 13.8634 10.6338 13.3462C10.6338 13.0773 10.709 12.8381 10.8594 12.6284C11.012 12.4165 11.2297 12.2513 11.5122 12.1328C11.797 12.0143 12.116 11.9551 12.4692 11.9551C12.8247 11.9551 13.1414 12.02 13.4194 12.1499C13.6974 12.2775 13.9128 12.4587 14.0654 12.6934C14.2204 12.9281 14.2979 13.1947 14.2979 13.4932H13.2725C13.2725 13.2653 13.2007 13.0887 13.0571 12.9634C12.9136 12.8358 12.7119 12.772 12.4521 12.772C12.2015 12.772 12.0067 12.8255 11.8677 12.9326C11.7287 13.0374 11.6592 13.1764 11.6592 13.3496C11.6592 13.5114 11.7401 13.647 11.9019 13.7563C12.0659 13.8657 12.3063 13.9683 12.623 14.064C13.2064 14.2394 13.6313 14.457 13.8979 14.7168C14.1646 14.9766 14.2979 15.3001 14.2979 15.6875C14.2979 16.1182 14.1349 16.4565 13.8091 16.7026C13.4832 16.9465 13.0446 17.0684 12.4932 17.0684C12.1104 17.0684 11.7617 16.9989 11.4473 16.8599C11.1328 16.7186 10.8924 16.526 10.7261 16.2822C10.562 16.0384 10.48 15.7559 10.48 15.4346H11.5088C11.5088 15.9837 11.8369 16.2583 12.4932 16.2583C12.737 16.2583 12.9272 16.2093 13.064 16.1113C13.2007 16.0111 13.269 15.8721 13.269 15.6943Z"
                fill="white"
            />
        </svg>
    );
};

export const ImportXls = memo(ImportXlsInner);
