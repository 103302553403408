import React, { memo } from 'react';

const DoorInner = () => {
    return (
        <svg viewBox="0 0 30 28">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6135 0C4.76242 0 0 4.76242 0 10.6135C0 16.1669 4.28997 20.7395 9.72908 21.1906V19.4148C5.25137 18.9729 1.76892 15.2101 1.76892 10.6135C1.76892 5.71846 5.71846 1.76892 10.6135 1.76892C15.2101 1.76892 18.9729 5.25137 19.4148 9.72908H21.1906C20.7395 4.28997 16.1669 0 10.6135 0ZM9.72908 17.6341V15.8477C7.21211 15.4293 5.30677 13.2534 5.30677 10.6135C5.30677 7.67221 7.67221 5.30677 10.6135 5.30677C13.2534 5.30677 15.4293 7.21211 15.8477 9.72908H17.6341C17.1966 6.24658 14.2114 3.53785 10.6135 3.53785C6.71622 3.53785 3.53785 6.71622 3.53785 10.6135C3.53785 14.2114 6.24658 17.1966 9.72908 17.6341ZM9.73049 14.0388C9.74582 13.4255 9.886 12.8432 10.1266 12.3164C9.38092 12.1084 8.84462 11.4318 8.84462 10.6135C8.84462 9.62589 9.6259 8.84462 10.6135 8.84462C11.4318 8.84462 12.1085 9.38092 12.3164 10.1266C12.8432 9.886 13.4255 9.74582 14.0388 9.73049C13.6432 8.20958 12.2525 7.0757 10.6135 7.0757C8.67009 7.0757 7.0757 8.67009 7.0757 10.6135C7.0757 12.2525 8.20959 13.6432 9.73049 14.0388ZM15.7276 11.498H25.7695C28.1845 11.498 29.7551 13.077 29.9737 15.3798C29.9137 15.362 29.8502 15.3524 29.7844 15.3524L11.7127 15.3524C11.6473 15.3524 11.5841 15.3618 11.5244 15.3795C11.744 13.0769 13.3199 11.498 15.7276 11.498ZM11.498 16.6436V17.9696C11.5654 17.9465 11.6376 17.9341 11.7127 17.9341H29.7844C29.8599 17.9341 29.9324 17.9467 30 17.9699V16.6433C29.9324 16.6665 29.8599 16.6791 29.7844 16.6791L11.7127 16.6791C11.6376 16.6791 11.5654 16.6666 11.498 16.6436ZM11.498 22.9727V19.2252C11.5654 19.2483 11.6376 19.2607 11.7127 19.2607H29.7844C29.8599 19.2607 29.9324 19.2481 30 19.2249V22.9727C30 25.5776 28.377 27.4183 25.7686 27.4183H15.7276C13.1202 27.4183 11.498 25.5776 11.498 22.9727Z"
            />
        </svg>
    );
};

export const Door = memo(DoorInner);
