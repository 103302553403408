import React, { memo } from 'react';

const EditInner = () => {
    return (
        <svg viewBox="0 0 20 21">
            <path d="M12.6451 4.35495L2.75577 14.2565L1.45715 17.9492C1.40071 18.57 1.92084 19.0902 2.5417 19.0337L6.28518 17.7859L16.1745 7.88436L12.6451 4.35495Z" />
            <path d="M16.8804 7.17848L18.998 5.06082C19.3879 4.67097 19.3879 4.0389 18.998 3.64906L16.8804 1.53141C16.4905 1.14156 15.8585 1.14156 15.4686 1.53141L13.3509 3.64907L16.8804 7.17848Z" />
        </svg>
    );
};

export const Edit = memo(EditInner);
