import axios from 'axios';
import { Dispatch } from 'react';
import store from 'redux/store';
import {
    AccessModule,
    SetupWizardDevicesActions,
    DeviceTypes,
    DeviceType,
    AccessZones,
    AccessZone,
    ControllerTypes,
    ControllerType,
    ConnectionTypes,
    ConnectionType,
    SETUP_WIZAR_DEVICES_POINT_TYPE,
    SETUP_WIZAR_DEVICES_POINT_TYPES,
    SETUP_WIZAR_DEVICES_ACCESS_ZONES,
    SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_IN,
    SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_OUT,
    SETUP_WIZAR_DEVICES_CONTROLLER_TYPES,
    SETUP_WIZAR_DEVICES_CONTROLLER_TYPE,
    SETUP_WIZAR_DEVICES_CONNECTION_TYPES,
    SETUP_WIZAR_DEVICES_CONNECTION_TYPE,
    SETUP_WIZAR_DEVICES_POINT_NAME,
    SETUP_WIZAR_DEVICES_POINT_DESCRIPTION,
    SETUP_WIZAR_DEVICES_POINT_PROTOCOL,
    SETUP_WIZAR_DEVICES_POINT_MASK,
    SETUP_WIZAR_DEVICES_POINT_IP,
    SETUP_WIZAR_DEVICES_POINT_SERVER_PORT,
    SETUP_WIZAR_DEVICES_POINT_CLIENT_PORT,
    SETUP_WIZAR_DEVICES_POINT_PORT,
    SETUP_WIZAR_DEVICES_POINT_SPEED,
    SETUP_WIZAR_DEVICES_POINT_PARITY,
    SETUP_WIZAR_DEVICES_POINT_BIT,
    SETUP_WIZAR_DEVICES_POINT_STOP_BIT,
    SETUP_WIZAR_DEVICES_POINT_SERIAL_NUMBER,
    SETUP_WIZAR_DEVICES_POINT_BUS_PORT,
    SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_ADD,
    SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_REMOVE,
    SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_DIRECTION,
    SETUP_WIZAR_DEVICES_SEQUENCE_NOT_IMPORTANT,
    SETUP_WIZAR_DEVICES_USE_FOR_TIME_TRACKING,
    SETUP_WIZAR_DEVICES_ALLOW_OFFLINE_WORK,
    SETUP_WIZAR_DEVICES_CLEAR
} from './setupWizardDevicesTypes';

export const setupWizardDevicesTypes = (types: DeviceTypes): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_TYPES,
        payload: types
    };
};

export const setupWizardDevicesType = (type: DeviceType): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_TYPE,
        payload: type
    };
};

export const setupWizardDevicesAccessZones = (accessZones: AccessZones): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_ACCESS_ZONES,
        payload: accessZones
    };
};

export const setupWizardDevicesAccessZoneOnIn = (accessZoneOnIn: AccessZone): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_IN,
        payload: accessZoneOnIn
    };
};

export const setupWizardDevicesAccessZoneOnOut = (accessZoneOnOut: AccessZone): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_ACCESS_ZONE_ON_OUT,
        payload: accessZoneOnOut
    };
};

export const setupWizardDevicesControllerTypes = (controllerTypes: ControllerTypes): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_CONTROLLER_TYPES,
        payload: controllerTypes
    };
};

export const setupWizardDevicesControllerType = (controllerType: ControllerType): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_CONTROLLER_TYPE,
        payload: controllerType
    };
};

export const setupWizardDevicesConnectionTypes = (connectionTypes: ConnectionTypes): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_CONNECTION_TYPES,
        payload: connectionTypes
    };
};

export const setupWizardDevicesConnectionType = (connectionType: ConnectionType): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_CONNECTION_TYPE,
        payload: connectionType
    };
};

export const setupWizardDevicesPointName = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_NAME,
        payload
    };
};

export const setupWizardDevicesPointDescription = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_DESCRIPTION,
        payload
    };
};

export const setupWizardDevicesPointProtocol = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_PROTOCOL,
        payload
    };
};

export const setupWizardDevicesPointIp = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_IP,
        payload
    };
};

export const setupWizardDevicesPointMask = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_MASK,
        payload
    };
};

export const setupWizardDevicesPointServerPort = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_SERVER_PORT,
        payload
    };
};

export const setupWizardDevicesPointClientPort = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_CLIENT_PORT,
        payload
    };
};

export const setupWizardDevicesPointPort = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_PORT,
        payload
    };
};

export const setupWizardDevicesPointSpeed = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_SPEED,
        payload
    };
};

export const setupWizardDevicesPointParity = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_PARITY,
        payload
    };
};

export const setupWizardDevicesPointBit = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_BIT,
        payload
    };
};

export const setupWizardDevicesPointStopBit = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_STOP_BIT,
        payload
    };
};

export const setupWizardDevicesPointSerialNumber = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_SERIAL_NUMBER,
        payload
    };
};

export const setupWizardDevicesPointBusPort = (payload: string): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_BUS_PORT,
        payload
    };
};

export const setupWizardDevicesPointAccessModuleAdd = (payload: AccessModule): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_ADD,
        payload
    };
};

export const setupWizardDevicesPointAccessModuleRemove = (payload: AccessModule[]): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_REMOVE,
        payload
    };
};

export const setupWizardDevicesPointAccessModuleDirection = (
    payload: AccessModule[]
): SetupWizardDevicesActions => {
    return {
        type: SETUP_WIZAR_DEVICES_POINT_ACCESS_MODULE_DIRECTION,
        payload
    };
};

export const setupWizardDevicesSequenceNotImportant = (): SetupWizardDevicesActions => ({
    type: SETUP_WIZAR_DEVICES_SEQUENCE_NOT_IMPORTANT
});

export const setupWizardDevicesUseForTimeTracking = (): SetupWizardDevicesActions => ({
    type: SETUP_WIZAR_DEVICES_USE_FOR_TIME_TRACKING
});

export const setupWizardDevicesAllowOfflineWork = (): SetupWizardDevicesActions => ({
    type: SETUP_WIZAR_DEVICES_ALLOW_OFFLINE_WORK
});

export const setupWizardDevicesClear = (): SetupWizardDevicesActions => ({
    type: SETUP_WIZAR_DEVICES_CLEAR
});

export const requestDeviceTypes = () => (dispatch: Dispatch<SetupWizardDevicesActions>) => {
    axios
        .get(`${store.getState().app.config.apiUrl}/devices/types`)
        .then((response) => {
            dispatch(setupWizardDevicesTypes(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const requestDeviceAccessZones = () => (dispatch: Dispatch<SetupWizardDevicesActions>) => {
    axios
        .get(`${store.getState().app.config.apiUrl}/zones`)
        .then((response) => {
            dispatch(setupWizardDevicesAccessZones(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const requestDeviceControllersTypes = (deviceTypeId: number) => (dispatch: Dispatch<SetupWizardDevicesActions>) => {
    axios
        .get(`${store.getState().app.config.apiUrl}/controllers/types?deviceTypeId=${deviceTypeId}`)
        .then((response) => {
            dispatch(setupWizardDevicesControllerTypes(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const requestDeviceСonnectionsTypes = (controllerTypeId: number) => (dispatch: Dispatch<SetupWizardDevicesActions>) => {
    axios
        .get(`${store.getState().app.config.apiUrl}/connections/types?controllerTypeId=${controllerTypeId}`)
        .then((response) => {
            dispatch(setupWizardDevicesConnectionTypes(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
};
