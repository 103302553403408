import React, { memo } from 'react';

export const SpringLogoInner = () => {
    return (
        <svg viewBox="0 0 97 26">
            <path
                d="M32,18c-0.3-0.2-0.5-0.5-0.5-1c0-0.6,0.5-1.1,1.1-1.1c0.2,0,0.4,0.1,0.6,0.2c1.2,0.8,2.4,1.2,3.5,1.2
			c1.2,0,1.9-0.5,1.9-1.3v0c0-0.9-1.3-1.3-2.7-1.7C34,13.6,32,12.9,32,10.6v0c0-2.3,1.9-3.7,4.3-3.7c1.3,0,2.6,0.4,3.8,1
			c0.4,0.2,0.7,0.6,0.7,1.1c0,0.6-0.5,1.1-1.1,1.1c-0.2,0-0.4,0-0.5-0.1c-1-0.5-2-0.8-2.8-0.8c-1.1,0-1.7,0.5-1.7,1.2v0
			c0,0.9,1.3,1.3,2.7,1.7c1.8,0.6,3.8,1.4,3.8,3.5v0c0,2.5-2,3.8-4.5,3.8C35,19.4,33.3,18.9,32,18z"
            />
            <path
                d="M51.8,13.1L51.8,13.1c0-2.4-1.6-3.9-3.5-3.9c-1.9,0-3.5,1.6-3.5,3.9v0c0,2.3,1.6,3.9,3.5,3.9C50.2,17,51.8,15.5,51.8,13.1
			z M42.1,8.2c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4v0.8C45.8,7.8,47,6.8,49,6.8c2.8,0,5.6,2.2,5.6,6.3v0
			c0,4-2.7,6.3-5.6,6.3c-2,0-3.3-1-4.1-2.1v4.3c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4V8.2z"
            />
            <path
                d="M55.6,8.2c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4v0.7c0.1-1,1.8-2.1,3-2.1c0.9,0,1.4,0.6,1.4,1.4
			c0,0.7-0.5,1.2-1.1,1.3c-2,0.3-3.3,2.1-3.3,4.5v4c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4V8.2z"
            />
            <path d="M63.9,8.3c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4V18c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4V8.3z" />
            <path
                d="M68,8.3c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4v0.6c0.8-1.1,1.9-2,3.8-2c2.7,0,4.3,1.8,4.3,4.6V18
			c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4v-5.7c0-1.9-0.9-3-2.6-3c-1.6,0-2.7,1.1-2.7,3V18c0,0.8-0.6,1.4-1.4,1.4
			c-0.8,0-1.4-0.6-1.4-1.4V8.3z"
            />
            <path
                d="M86.2,17c-1.9,0-3.5-1.5-3.5-3.9v0c0-2.3,1.6-3.9,3.5-3.9c1.9,0,3.5,1.6,3.5,3.9v0C89.8,15.4,88.1,17,86.2,17z M91.1,6.8
			c-0.8,0-1.4,0.6-1.4,1.4v0.8c-0.9-1.3-2.2-2.3-4.1-2.3C82.7,6.8,80,9,80,13.1v0c0,4,2.7,6.3,5.6,6.3c2,0,3.3-1,4.1-2.1
			c-0.1,2.2-1.5,3.3-3.8,3.3c-1.4,0-2.6-0.3-3.7-1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.6,0-1.2,0.5-1.2,1.1c0,0.5,0.3,0.9,0.8,1.1
			c1.5,0.7,3,1.1,4.7,1.1c2.2,0,3.9-0.5,5-1.6c1-1,1.6-2.5,1.6-4.6V8.2C92.5,7.4,91.9,6.8,91.1,6.8z"
            />
            <path d="M66.7,3.5c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4C66,2.1,66.7,2.7,66.7,3.5z" />
            <path
                d="M22.7,18.6c-3.1,4.1-9.6,2.7-13.8,2.9c0,0-0.7,0-1.5,0.2c0,0,0.3-0.1,0.6-0.2c3-1,4.4-1.2,6.1-2.2
			c3.4-1.7,6.7-5.5,7.4-9.4c-1.3,3.8-5.2,7-8.7,8.3c-2.4,0.9-6.8,1.8-6.8,1.8c0,0-0.2-0.1-0.2-0.1c-3-1.5-3.1-8,2.4-10.1
			c2.4-0.9,4.7-0.4,7.2-1c2.7-0.7,5.9-2.7,7.2-5.4C24,7.7,25.8,14.4,22.7,18.6z M22.7,2c-0.4,0.9-0.8,1.7-1.3,2.4
			C19.1,2,16,0.6,12.5,0.6C5.6,0.6,0,6.2,0,13.1c0,3.6,1.5,6.9,4,9.2l0.3,0.2c-0.5-0.4-0.5-1-0.2-1.5c0.4-0.5,1-0.5,1.5-0.2
			c0.5,0.4,0.5,1,0.2,1.5c-0.4,0.5-1,0.5-1.5,0.2l0.2,0.2c2.2,1.8,5,2.9,8,2.9c6.6,0,12-5.1,12.4-11.6C25.2,10.8,24.3,6.8,22.7,2z"
            />
            <path
                d="M95.7,7.7h-0.4v0.6h0.4C95.8,8.3,96,8.2,96,8C96,7.8,95.8,7.7,95.7,7.7z M96,9.1l-0.4-0.6h-0.3v0.6H95V7.5h0.7
			c0.3,0,0.5,0.2,0.5,0.5c0,0.4-0.3,0.5-0.4,0.5l0.4,0.7H96z M95.6,7.1c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2
			c0.7,0,1.2-0.5,1.2-1.2C96.8,7.6,96.2,7.1,95.6,7.1z M95.6,9.7c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
			c0.8,0,1.4,0.6,1.4,1.4C97,9.1,96.4,9.7,95.6,9.7z"
            />
        </svg>
    );
};

export const SpringLogo = memo(SpringLogoInner);
