import React, { memo } from 'react';

const SecurityCallInner = () => {
    return (
        <svg viewBox="0 0 20 18">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4773 1.44209L19.746 14.0572C19.906 14.4338 19.976 14.7399 19.996 15.058C20.036 15.8012 19.776 16.5236 19.2661 17.0795C18.7562 17.6334 18.0663 17.9604 17.3164 18H2.6789C2.36896 17.9812 2.05901 17.9108 1.76906 17.8018C0.319301 17.2172 -0.380581 15.5723 0.20932 14.1464L7.52809 1.43317C7.77804 0.986278 8.15798 0.600818 8.6279 0.353094C9.98767 -0.40098 11.7174 0.0944694 12.4773 1.44209ZM10.8675 9.75573C10.8675 10.2314 10.4776 10.6287 9.99767 10.6287C9.51775 10.6287 9.11782 10.2314 9.11782 9.75573V6.95248C9.11782 6.47585 9.51775 6.09039 9.99767 6.09039C10.4776 6.09039 10.8675 6.47585 10.8675 6.95248V9.75573ZM9.99767 14.0176C9.51775 14.0176 9.11782 13.6202 9.11782 13.1456C9.11782 12.669 9.51775 12.2726 9.99767 12.2726C10.4776 12.2726 10.8675 12.6601 10.8675 13.1347C10.8675 13.6202 10.4776 14.0176 9.99767 14.0176Z"
            />
        </svg>
    );
};

export const SecurityCall = memo(SecurityCallInner);
