import React, { memo } from 'react';

const PieInner = () => {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M13 2.051V11h8.949C21.479 6.283 17.717 2.521 13 2.051zM17.969 20.004c2.189-1.637 3.694-4.14 3.98-7.004h-8.183L17.969 20.004z" />
            <path d="M11,12V2.051C5.954,2.555,2,6.824,2,12c0,5.514,4.486,10,10,10c1.523,0,2.962-0.353,4.255-0.964 c0,0-5.253-8.915-5.254-9.031C11.001,12.003,11,12,11,12z" />
        </svg>
    );
};

export const Pie = memo(PieInner);
